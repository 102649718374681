/* eslint-disable no-restricted-syntax */

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {FC, useMemo} from 'react';
import { useTranslation } from 'react-i18next';
import {Loader, Table, Title} from 'ui-library';
import { useGetAssetsTableColumns } from 'datasource/useOtherAssets/useGetAssetsTableColumns';
import './AssetsDataTable.css';
import { CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE } from 'constants/constants';

const AssetsDataTable:FC<any> = ({
    data: tableData, totalAssetsData, onEditAssetRow, categoryOptions, instituteOptions, strategyOptions, loading,
}) => {
    const {getColumns} = useGetAssetsTableColumns();
    const {t} = useTranslation();

    if (loading) {
        return <Loader />;
    }
    const tableDataNotLifeInsurance = useMemo(() => tableData?.filter(dta => dta.categoryListItemId !== CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE), [tableData]);
    const tableDataLifeInsurance = useMemo(() => tableData?.filter(dta => dta.categoryListItemId === CATEGORY_LIST_ITEM_ID_FOR_LIFE_INSURANCE), [tableData]);

    const getTotalTableValues = [getColumns({})[0],{...getColumns({})[1],minWidth: 150,maxWidth: 150},...getColumns({}).slice(2)]


    return (
        <div className="assets-data-table-wrapper">
            <div className="mb-40">
                <Title type={2} className="font-normal">
                    {t('OtherAssetStep.table.title.Portfolios&AssetManagement')}
                    {/* Portfolios & Asset Management */}
                </Title>
                <Table
                    className="assets-data-table"
                    columns={getColumns({
                        onEditAssetRow, categoryOptions, instituteOptions, strategyOptions,
                    })}
                    data={tableDataNotLifeInsurance}
                    orderColumns
                />
            </div>

            <div className="mb-40">
                <Title type={2} className="font-normal">
                    {t('OtherAssetStep.table.title.LifeInsurance')}
                    {/* Life insurances */}
                </Title>
                <Table
                    className="assets-data-table"
                    columns={getColumns({
                        onEditAssetRow, categoryOptions, instituteOptions, strategyOptions,
                    })}
                    data={tableDataLifeInsurance}
                    orderColumns
                />
            </div>

            {totalAssetsData?.instituteListItemId && (
                <Table
                    wrapperClass="total-assets-data-table-wrapper"
                    className="total-assets-data-table"
                    columns={getTotalTableValues}
                    data={[{...totalAssetsData}]}
                    orderColumns
                />
            )}

        </div>
    );
};

export default AssetsDataTable;
