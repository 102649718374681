import { AdvisoryProcess } from 'widgets/AdvisoryProcess';
import { useMemo } from 'react';
import { useCreatePortfolioSelectors, useCreatePortfolioState } from 'datasource/CreatePortfolio';
import { useRealEstateData } from 'datasource/useRealEstateData/useRealEstateData';
import { useOtherAssets } from 'datasource/useOtherAssets/useOtherAssets';
import { useAdvisoryProcess } from 'datasource/useAdvisoryProcess';
import { useProductOffers } from 'datasource/useProductOffers';
import { useMultiplePersonalDetails } from 'datasource/usePersonalDetails/useMultiplePersonalDetails';
import { useIncomeAndLiquidity } from 'datasource/useIncomeAndLiquidity/useIncomeAndLiquidity';
import { usePensionSituation } from 'datasource/usePensionSituation/usePensionSituation';
import useAssetsOverview from 'datasource/useAssetsOverview/useAssetsOverview';
import { useStrategyRiskComparison } from 'datasource/useStrategyRiskComparison';
import { useInvestDepots } from 'datasource/useInvestDepots/useInvestDepots';
import useStrategy from 'datasource/useStrategy';
import { useInvestorProfile } from 'datasource/useInvestorProfile';
import useShoppingCart from 'datasource/useShoppingCart';
import { ShoppingCard } from '../ShoppingCard';
import IncomeAndLiquidity from '../IncomeAndLiquidity/IncomeAndLiquidity';
import { ProductOffers } from '../ProductOffers';
import { Step, StepNavigation, StepsType } from '../../core/stepper/Stepper.types';
import InvestDepots from '../InvestDepots/InvestDepots';
import AssetsOverview from '../AssetsOverview/AssetsOverview';
import RealEstate from '../RealEstate';
import OtherAssets from '../OtherAssets/OtherAssets';
import PensionSituation from '../PensionSituation';
import StrategyRiskComparison from '../StrategyRiskComparison';
import { flattenSteps, sectionOfSteps } from '../../utils/stepDefintion';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import Strategy from '../Strategy/Strategy';
import { InvestorProfiles } from '../InvestorProfile/InvestorProfiles';
import StrategySelectionPage from '../StrategySelection/StrategySelectionPage';
import { GoalAndPortfolioSetup } from '../GoalAndPortfolioSetup';
import AgentInformation from '../AgentInformation';
import { QualityCheckWithProvider } from '../QualityCheck/with-provider';
import { Finalization } from '../Finalization';
import FeesOverview from '../FeesOverview';
import { useWithdrawalPlan } from '../WithdrawalPlan/hooks/useWithdrawalPlan';
import WithdrawalPlan from '../WithdrawalPlan/WithdrawalPlan';
import useCommonAllocationDataProvider
    from '../../pages/ClientOverview/pages/Portfolios/context/useCommonAllocationDataProvider.';
import { KYCMain } from '../KYC/KYCMain';

export const stepsKeys = {
    // AdvisoryProcess: 'AdvisoryProcess',
    ClientDashboard: 'ClientDashboard',
    GeneralProductSelection: 'GeneralProductSelection',
    ShoppingCard: 'ShoppingCard',
    Non3BShoppingCard: 'Non3BShoppingCard',
    Overview: 'Overview',
    AdvisoryTypeSelection: 'AdvisoryTypeSelection',
    IncomeAndLiquidity: 'IncomeAndLiquidity',
    ZurichInvestDepots: 'ZurichInvestDepots',
    AssetsOverview: 'AssetsOverview',
    PersonalDetails: 'PersonalDetails',
    RealEstate: 'RealEstate',
    OtherInvestments: 'OtherInvestments',
    PensionProvision: 'PensionProvision',
    AdvisoryInvestorProfile: 'AdvisoryInvestorProfile',
    AdvisoryStrategy: 'AdvisoryStrategy',
    StrategyComparison: 'StrategyComparison',
    InvestorProfile: 'InvestorProfile',
    InvestorProfile3b: 'InvestorProfile3b',
    InvestorProfileUploadedAdvisory: 'InvestorProfileUploadedAdvisory',
    Strategy: 'Strategy',
    Performance: 'Performance',
    AgentInformation: 'AgentInformation',
    QualityCheck: 'QualityCheck',
    WithdrawalPlan: 'WithdrawalPlan',
    WithdrawalPlanAZP: 'WithdrawalPlanAZP',
    KYC: 'KYC',
    Submit: 'Submit',
    Strategy3b: 'Strategy3b',
    Performance3b: 'Performance3b',
    AgentInformation3b: 'AgentInformation3b',
    PersonalDetails3b: 'PersonalDetails3b',
};

export const process = {
    main: 'parent',
    advisory: 'advisory',
    productOffer: 'productOffer',
};

const getNextStepKey = (navigationSubject: StepNavigation<string> | undefined) => {
    if (Array.isArray(navigationSubject)) {
        const nextStep = navigationSubject.find((item) => (item.condition()));

        if (nextStep) {
            return nextStep.target;
        }

        return undefined;
    }

    return navigationSubject;
};

export const useCreatePortfolioFlow = () => {
    const {
        isAdvisoryProcessSelector,
        is3bProductGroupSelector,
        selectedProductDescSelector,
        isAzp, isPk,
        advisoryProcessInProgressSelector,
    } = useCreatePortfolioSelectors();
    const {
        isProductSetup,
    } = useCreatePortfolioState();

    const { setupWithdrawalPlan: isWithdrawal } = useCommonAllocationDataProvider();

    const { uploadedAdvisoryDocument } = useCreatePortfolioState();

    const createPortfolioFlow: StepsType = useMemo(() => ({
        [stepsKeys.GeneralProductSelection]: {
            datasource: useProductOffers,
            process: process.productOffer,
            stepperTitle: 'portfolio.createNewPortfolio.productOffer',
            contentTitle: 'portfolio.createNewPortfolio.selectProductOffer',
            component: ProductOffers,
            url: '',
            next: [
                {
                    target: stepsKeys.Non3BShoppingCard,
                    condition: () => !is3bProductGroupSelector,
                },
                {
                    target: stepsKeys.AdvisoryTypeSelection,
                    condition: () => is3bProductGroupSelector,
                },
            ],
            isBegin: true,
        },
        [stepsKeys.AdvisoryTypeSelection]: {
            datasource: useAdvisoryProcess,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.advisoryDocument',
            contentTitle: 'advisoryDocumentInit.step.title',
            component: AdvisoryProcess,
            url: 'advisory/process-selection',
            prev: [
                {
                    target: stepsKeys.GeneralProductSelection,
                    condition: () => !isAdvisoryProcessSelector,
                },
            ],
            next: [
                {
                    target: stepsKeys.IncomeAndLiquidity,
                    condition: () => advisoryProcessInProgressSelector,
                },
                {
                    target: stepsKeys.InvestorProfileUploadedAdvisory,
                    condition: () => !advisoryProcessInProgressSelector && !!uploadedAdvisoryDocument,
                },
                {
                    target: stepsKeys.ShoppingCard,
                    condition: () => !advisoryProcessInProgressSelector,
                },
            ],
            children: {
                [stepsKeys.IncomeAndLiquidity]: {
                    datasource: useIncomeAndLiquidity,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.IncomeAndLiquidity.title',
                    contentClassName: 'portfolio-process-content-wrapper',
                    contentTitle: 'advisoryFlow.IncomeAndLiquidity.title',
                    component: IncomeAndLiquidity,
                    url: 'advisory/income-and-liquidity',
                    next: stepsKeys.RealEstate,
                    prev: stepsKeys.AdvisoryTypeSelection,
                },
                [stepsKeys.RealEstate]: {
                    datasource: useRealEstateData,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.RealEstate.stepper.title',
                    contentTitle: 'advisoryFlow.RealEstate.title',
                    contentClassName: 'portfolio-process-content-wrapper',
                    component: RealEstate,
                    url: 'advisory/real-estate',
                    next: stepsKeys.ZurichInvestDepots,
                    prev: stepsKeys.IncomeAndLiquidity,
                },
                [stepsKeys.ZurichInvestDepots]: {
                    datasource: useInvestDepots,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.ZurichInvestDepots.stepper.title',
                    contentTitle: 'advisoryFlow.ZurichInvestDepots.title',
                    contentClassName: 'portfolio-process-content-wrapper',
                    component: InvestDepots,
                    url: 'advisory/investment-depot',
                    next: stepsKeys.OtherInvestments,
                    prev: stepsKeys.RealEstate,
                },
                [stepsKeys.OtherInvestments]: {
                    datasource: useOtherAssets,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.OtherInvestments.stepper.title',
                    contentTitle: 'advisoryFlow.OtherInvestments.title',
                    component: OtherAssets,
                    url: 'advisory/other-investments',
                    next: stepsKeys.PensionProvision,
                    prev: stepsKeys.ZurichInvestDepots,
                },
                [stepsKeys.PensionProvision]: {
                    datasource: usePensionSituation,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.PensionProvision.stepper.title',
                    contentTitle: 'advisoryFlow.PensionProvision.title',
                    contentClassName: 'portfolio-process-content-wrapper',
                    component: PensionSituation,
                    url: 'advisory/pension-provision',
                    next: stepsKeys.AssetsOverview,
                    prev: stepsKeys.OtherInvestments,
                },
                [stepsKeys.AssetsOverview]: {
                    datasource: useAssetsOverview,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.AssetsOverview.stepper.title',
                    contentTitle: 'advisoryFlow.AssetsOverview.title',
                    contentClassName: 'portfolio-process-content-wrapper',
                    component: AssetsOverview,
                    url: 'advisory/assets-overview',
                    next: stepsKeys.AdvisoryInvestorProfile,
                    prev: stepsKeys.PensionProvision,
                },
                [stepsKeys.AdvisoryInvestorProfile]: {
                    datasource: useInvestorProfile,
                    process: process.main,
                    stepperTitle: 'advisoryFlow.InvestorProfile.stepper.title',
                    component: InvestorProfiles,
                    url: 'advisory/investor-profile',
                    next: stepsKeys.AdvisoryStrategy,
                    prev: stepsKeys.AssetsOverview,
                },
                [stepsKeys.AdvisoryStrategy]: {
                    datasource: useStrategy,
                    process: process.main,
                    stepperTitle: 'portfolioDetail.overview.strategy',
                    contentTitle: 'advisoryFlow.Strategy.content.title',
                    component: Strategy,
                    url: 'advisory/strategy',
                    next: stepsKeys.ShoppingCard,
                    prev: stepsKeys.AdvisoryInvestorProfile,
                },
            },
        },
        [stepsKeys.InvestorProfileUploadedAdvisory]: {
            datasource: useInvestorProfile,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.investorProfile',
            contentTitle: selectedProductDescSelector,
            component: InvestorProfiles,
            url: 'product-configuration/investor-profile',
            next: stepsKeys.ShoppingCard,
            prev: stepsKeys.AdvisoryTypeSelection,
        },
        [stepsKeys.ShoppingCard]: {
            datasource: useShoppingCart,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.overview',
            contentTitle: 'portfolio.createNewPortfolio.overview.personalOverviewTitle',
            component: ShoppingCard,
            url: 'shopping',
            prev: [
                {
                    target: stepsKeys.AdvisoryStrategy,
                    condition: () => advisoryProcessInProgressSelector,
                },
                {
                    target: stepsKeys.AdvisoryTypeSelection,
                    condition: () => !advisoryProcessInProgressSelector && !uploadedAdvisoryDocument,
                },
                {
                    target: stepsKeys.InvestorProfileUploadedAdvisory,
                    condition: () => !advisoryProcessInProgressSelector && !!uploadedAdvisoryDocument,
                },
            ],
            next: [
                {
                    target: stepsKeys.PersonalDetails3b,
                    condition: () => isProductSetup === true,
                },
                {
                    target: stepsKeys.StrategyComparison,
                    condition: () => !isProductSetup && advisoryProcessInProgressSelector,
                },
                {
                    target: stepsKeys.KYC,
                    condition: () => !isProductSetup,
                },
            ],
            children: {
                [stepsKeys.PersonalDetails3b]: {
                    datasource: useMultiplePersonalDetails,
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.personalDetails',
                    contentTitle: selectedProductDescSelector,
                    component: PersonalDetails,
                    url: 'product-configuration/personal-details',
                    next: [
                        {
                            target: stepsKeys.AgentInformation3b,
                            condition: () => isPk && isAdvisoryProcessSelector,
                        },
                        {
                            target: stepsKeys.Strategy3b,
                            condition: () => isAdvisoryProcessSelector,
                        },
                        {
                            target: stepsKeys.InvestorProfile3b,
                            condition: () => !isAdvisoryProcessSelector,
                        },
                    ],
                    prev: stepsKeys.ShoppingCard,
                },
                [stepsKeys.InvestorProfile3b]: {
                    datasource: useInvestorProfile,
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.investorProfile',
                    contentTitle: selectedProductDescSelector,
                    component: InvestorProfiles,
                    url: 'product-configuration/investor-profile',
                    next: [
                        {
                            target: stepsKeys.AgentInformation3b,
                            condition: () => isPk,
                        },
                        {
                            target: stepsKeys.Strategy3b,
                            condition: () => true,
                        },
                    ],
                    prev: stepsKeys.PersonalDetails3b,
                },
                [stepsKeys.Strategy3b]: {
                    datasource: () => {},
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.strategy',
                    component: StrategySelectionPage,
                    url: 'product-configuration/strategy',
                    next: [
                        {
                            target: stepsKeys.WithdrawalPlanAZP,
                            condition: () => isAzp,
                        },
                        {
                            target: stepsKeys.Performance3b,
                            condition: () => !isAzp,
                        },
                    ],
                    prev: [
                        {
                            target: stepsKeys.PersonalDetails3b,
                            condition: () => isAdvisoryProcessSelector,
                        },
                        {
                            target: stepsKeys.InvestorProfile3b,
                            condition: () => !isAdvisoryProcessSelector,
                        },
                    ],
                },
                [stepsKeys.WithdrawalPlanAZP]: {
                    datasource: useWithdrawalPlan,
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.withdrawalPlan',
                    component: WithdrawalPlan,
                    url: 'product-configuration/withdrawal-plan',
                    next: stepsKeys.Performance3b,
                    prev: stepsKeys.Strategy3b,
                },
                [stepsKeys.Performance3b]: {
                    datasource: () => {},
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.productConfiguration',
                    component: GoalAndPortfolioSetup,
                    url: 'product-configuration/performance',
                    next: [
                        {
                            target: stepsKeys.WithdrawalPlan,
                            condition: () => !isAzp && isWithdrawal,
                        },
                        {
                            target: stepsKeys.AgentInformation3b,
                            condition: () => isAzp || (!isAzp && !isWithdrawal),
                        },
                    ],
                    prev: [
                        {
                            target: stepsKeys.WithdrawalPlanAZP,
                            condition: () => isAzp,
                        },
                        {
                            target: stepsKeys.Strategy3b,
                            condition: () => !isAzp,
                        },
                    ],
                },
                [stepsKeys.WithdrawalPlan]: {
                    datasource: useWithdrawalPlan,
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.withdrawalPlan',
                    component: WithdrawalPlan,
                    url: 'product-configuration/withdrawal-plan',
                    next: stepsKeys.AgentInformation3b,
                    prev: stepsKeys.Performance3b,
                },
                [stepsKeys.AgentInformation3b]: {
                    datasource: () => {},
                    process: process.main,
                    stepperTitle: 'portfolio.createNewPortfolio.agentInformation',
                    component: FeesOverview,
                    url: 'product-configuration/agent-information',
                    next: [
                        {
                            target: stepsKeys.StrategyComparison,
                            condition: () => (is3bProductGroupSelector && isAdvisoryProcessSelector && !uploadedAdvisoryDocument),
                        },
                        {
                            target: stepsKeys.KYC,
                            condition: () => true,
                        },
                    ],
                    prev: [
                        {
                            target: stepsKeys.WithdrawalPlan,
                            condition: () => isWithdrawal && !isAzp,
                        },
                        {
                            target: stepsKeys.PersonalDetails3b,
                            condition: () => isPk,
                        },
                        {
                            target: stepsKeys.Performance3b,
                            condition: () => true,
                        },
                    ],
                },
            },
        },
        [stepsKeys.Non3BShoppingCard]: {
            datasource: useShoppingCart,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.overview',
            contentTitle: 'portfolio.createNewPortfolio.overview.personalOverviewTitle',
            component: ShoppingCard,
            url: 'shopping-card',
            next: [
                {
                    target: stepsKeys.QualityCheck,
                    condition: () => !isProductSetup,
                },
                {
                    target: stepsKeys.PersonalDetails,
                    condition: () => isProductSetup as boolean,
                },
            ],
            prev: stepsKeys.GeneralProductSelection,
        },
        [stepsKeys.PersonalDetails]: {
            datasource: useMultiplePersonalDetails,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.personalDetails',
            contentTitle: selectedProductDescSelector,
            component: PersonalDetails,
            url: 'personal-details',
            next: stepsKeys.InvestorProfile,
            prev: stepsKeys.Non3BShoppingCard,
        },
        [stepsKeys.InvestorProfile]: {
            datasource: useInvestorProfile,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.investorProfile',
            contentTitle: selectedProductDescSelector,
            component: InvestorProfiles,
            url: 'investor-profile',
            next: stepsKeys.Strategy,
            prev: stepsKeys.PersonalDetails,
        },
        [stepsKeys.Strategy]: {
            datasource: () => {},
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.strategy',
            contentTitle: selectedProductDescSelector,
            component: StrategySelectionPage,
            url: 'strategy',
            next: stepsKeys.Performance,
            prev: stepsKeys.InvestorProfile,
        },
        [stepsKeys.Performance]: {
            datasource: () => {},
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.productConfiguration',
            component: GoalAndPortfolioSetup,
            url: 'performance',
            next: stepsKeys.AgentInformation,
            prev: stepsKeys.Strategy,
        },
        [stepsKeys.AgentInformation]: {
            datasource: () => {},
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.agentInformation',
            component: AgentInformation,
            url: 'agent-information',
            next: stepsKeys.QualityCheck,
            prev: stepsKeys.Performance,
        },
        [stepsKeys.StrategyComparison]: {
            datasource: useStrategyRiskComparison,
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.strategyComparison',
            contentTitle: 'portfolio.createNewPortfolio.strategyComparison.ContentTitle',
            component: StrategyRiskComparison,
            url: 'strategy-comparison',
            prev: stepsKeys.ShoppingCard,
            next: stepsKeys.KYC,
        },
        [stepsKeys.KYC]: {
            datasource: () => {},
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.KYC.new',
            contentTitle: 'portfolio.createNewPortfolio.KYC.new',
            component: KYCMain,
            url: 'kyc',
            prev: [
                {
                    target: stepsKeys.StrategyComparison,
                    condition: () => advisoryProcessInProgressSelector,
                },
                {
                    target: stepsKeys.ShoppingCard,
                    condition: () => true,
                },
            ],
            next: stepsKeys.QualityCheck,
        },
        [stepsKeys.QualityCheck]: {
            datasource: () => { },
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.qualityCheck',
            contentTitle: 'portfolio.createNewPortfolio.qualityCheck',
            component: QualityCheckWithProvider,
            url: 'quality-check',
            prev: [
                {
                    target: stepsKeys.Non3BShoppingCard,
                    condition: () => !is3bProductGroupSelector,
                },
                {
                    target: stepsKeys.KYC,
                    condition: () => is3bProductGroupSelector,
                },
            ],
            next: stepsKeys.Submit,
        },
        [stepsKeys.Submit]: {
            datasource: () => {
            },
            process: process.main,
            stepperTitle: 'portfolio.createNewPortfolio.Submit',
            component: Finalization,
            url: 'submit-application',
            prev: stepsKeys.QualityCheck,
            isEnd: true,
        },
    }), [
        isAdvisoryProcessSelector,
        uploadedAdvisoryDocument,
        is3bProductGroupSelector,
        selectedProductDescSelector,
        isWithdrawal, isAzp, isPk,
        isProductSetup,
        advisoryProcessInProgressSelector,
    ]);

    const flatSteps: StepsType = useMemo(() => flattenSteps(createPortfolioFlow), [createPortfolioFlow]);

    const stepsBasedOnState = useMemo(() => {
        const stepsKeysList: Array<string> = [];

        const findNextStep = (stepKey: string | undefined) => {
            if (!stepKey) {
                console.error('Flow defined with errors');

                return;
            }
            const step: Step = flatSteps[stepKey];

            stepsKeysList.push(stepKey);
            if (step.isEnd) {
                return;
            }

            const nextStepKey = getNextStepKey(step.next);

            findNextStep(nextStepKey);
        };

        findNextStep(stepsKeys.GeneralProductSelection);

        return sectionOfSteps(createPortfolioFlow, stepsKeysList);
    }, [flatSteps, createPortfolioFlow]);

    return {
        createPortfolioFlow,
        stepsBasedOnState,
    };
};
