/* eslint-disable no-case-declarations */
import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';
import moment from 'moment/moment';
import { useSelector as useReduxSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';
import { AdvisoryMetadataResponse, AdvisoryResponse } from 'core/types/api/Api';
import { AdvisoryOptionTypesEnum, useGetListItemsByKey } from 'hooks/rest/useGetListItemsByKey';
import { useGetStrategyAllocations } from 'hooks/rest/useGetStrategyAllocations';
import { useGetContact } from 'hooks/rest/useGetContact';
import { useProductsList } from 'hooks/useProducts';
import { useGetContactGroups } from 'hooks/rest/useGetContactGroups';
import { usePostAdvisoryProcessData } from 'hooks/rest/usePostAdvisoryProcessData';
import { APPLICATION_CONTAINER_ID, PRODUCT_VALUES_FOR_3B, PRODUCTS } from 'constants/constants';
import { ContactGroupEnum } from 'components/Connections/types';
import {
    CreatePortfolioAPI,
    CreatePortfolioState,
    SaveInvestmentApplicationDataParams,
} from './CreatePortfolioManager.types';
import { memberIdSelector } from '../../redux-store/auth/authSelectors';
import ServiceManager from '../../services/ServiceManager';
import { useClientProfile } from '../../prodivers/clientProfile';
import {
    DocumentType,
} from '../../pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/AdvisoryDocument/hooks/useAdvisoryDocumentBase';
import { IPersonalDetails } from '../../core/types/api/mix';
import { genarateDFSClientId } from '../../utils';
import { ConnectionsManager } from '../../components/Connections/components';
import { useThreeBProductType } from '../../hooks/isThreeBProduct';
import Preloader from '../../components/Preloader';

type TCreatePortfolioProps = {
    children: ReactNode;
    investmentApplicationId?: number;
}

const LOCAL_STORAGE_KEY = 'CreatePortfolio';

export const CreatePortfolioContext = createContext<CreatePortfolioAPI>(null as any);

const initialState = (): CreatePortfolioState => {
    const localStorageData = sessionStorage.getItem(LOCAL_STORAGE_KEY);

    return localStorageData ? JSON.parse(localStorageData) : {
        selectedProductId: 0,
        applications: {},
        isResumeCase: false,
    };
};

const reducer = (state = initialState(), action) => {
    switch (action.type) {
        case 'setProductData':
            return {
                ...state,
                readyToNavigate: true,
                applications: {
                    ...state.applications,
                    [String(state.currentInvestmentApplicationId)]: {
                        ...state.applications[String(state.currentInvestmentApplicationId)],
                        investmentDescription: {
                            ...state.applications[String(state.currentInvestmentApplicationId)]?.investmentDescription,
                            productExternalId: action.payload.productExternalId,
                            productId: action.payload.productId,
                        },
                    },
                },
            };
        case 'setAdvisoryProcessType':
            return {
                ...state,
                readyToNavigate: true,
                advisoryProcessType: action.payload,
            };
        case 'setAdvisoryProcess':
            return {
                ...state,
                readyToNavigate: true,
                isAdvisoryProcess: action.payload,
            };
        case 'setAdvisoryData':
            return {
                ...state,
                readyToNavigate: true,
                uploadedAdvisoryDocument: null,
                advisoryData: action.payload,
            };
        case 'setUploadedAdvisoryDocument':
            return {
                ...state,
                readyToNavigate: true,
                uploadedAdvisoryDocument: action.payload,
                advisoryProcessInProgress: false,
                advisoryData: {},
            };
        case 'setConnectionGroupId':
            return {
                ...state,
                readyToNavigate: true,
                applications: {
                    ...state.applications,
                    [String(state.currentInvestmentApplicationId)]: {
                        ...state.applications[String(state.currentInvestmentApplicationId)],
                        contactGroupId: action.payload,
                    },
                },
            };
        case 'lockNavigation':
            return {
                ...state,
                readyToNavigate: false,
            };
        case 'unlockNavigation':
            return {
                ...state,
                readyToNavigate: true,
            };
        case 'setShouldInitiateAdvisory':
            return {
                ...state,
                readyToNavigate: true,
                shouldInitiateAdvisoryProcess: action.payload,
            };
        case 'saveInvestorProfileClient':
            return {
                ...state,
                investorProfileClientId: action.payload,
            };
        case 'saveRecommendedStrategy':
            return {
                ...state,
                readyToNavigate: true,
                applications: {
                    ...state.applications,
                    [String(state.currentInvestmentApplicationId)]: {
                        ...state.applications[String(state.currentInvestmentApplicationId)],
                        ...action.payload,
                    },
                },
            };
        case 'resumeShoppingCard':
            return {
                ...state,
                readyToNavigate: true,
                is3B: action.payload.is3B,
                shouldInitiateAdvisoryProcess: action.payload.shouldInitiateAdvisoryProcess,
                currentInvestmentApplicationId: action.payload.applicationData.investmentApplicationId,
                advisoryData: action.payload.advisoryData,
                advisoryProcessInProgress: action.payload.advisoryProcessInProgress,
                applications: {
                    ...state.applications,
                    [action.payload.applicationData.investmentApplicationId]: {
                        ...state.applications[action.payload.applicationData.investmentApplicationId],
                        ...action.payload.applicationData,
                    },
                },
            };
        case 'saveInvestmentApplicationData':
            return {
                ...state,
                readyToNavigate: true,
                currentInvestmentApplicationId: action.payload.investmentApplicationId,
                applications: {
                    ...state.applications,
                    [action.payload.investmentApplicationId]: {
                        ...state.applications[action.payload.investmentApplicationId],
                        ...action.payload,
                    },
                },
            };
        case 'removeInvestmentApplicationData':
            const applications = { ...state.applications };

            delete applications[action.payload?.investmentApplicationId];

            const applicationsKeys = Object.keys(applications)?.filter(key => key !== 'undefined');
            const currentId = applicationsKeys.length > 0 ? +applicationsKeys[applicationsKeys.length - 1] : null;

            return {
                ...state,
                currentInvestmentApplicationId: currentId,
                applications,
            };
        case 'saveCurrentInvestmentApplicationId':
            return {
                ...state,
                currentInvestmentApplicationId: action.payload.investmentApplicationId,
            };
        case 'clearProductSetup':
            return {
                ...state,
                isProductSetup: false,
            };
        case 'startProductSetup':
            return {
                ...state,
                readyToNavigate: true,
                currentInvestmentApplicationId: action.payload.investmentApplicationId,
                isProductSetup: true,
            };
        case 'setProductId':
            return {
                ...state,
                readyToNavigate: true,
                selectedProductId: action.payload,
            };
        case 'setAdvisoryProcessInProgress':
            return {
                ...state,
                readyToNavigate: true,
                advisoryProcessInProgress: action.payload,
            };
        default:
            return state;
    }
};

const getMainClientData = (fullClientData: IPersonalDetails) => (fullClientData ? {
    id: fullClientData?.id,
    age: Math.ceil(moment(moment()
        .format('YYYY-MM-DD'))
        .diff(moment(fullClientData?.details?.personalInformation?.dateOfBirth), 'years', true)),
    name: `${fullClientData?.details?.personalInformation?.firstName} ${fullClientData?.details?.personalInformation?.lastName}`,
    countryOfResidence: fullClientData?.details?.communicationMethods?.primaryAddressCountry,
    taxData: fullClientData?.details?.taxSituation,
    gender: fullClientData?.details?.personalInformation?.gender,
    nationality: fullClientData?.details?.personalInformation?.identification?.[0]?.countryOfNationality,
} : undefined);

export const CreatePortfolioManager = ({
    children, investmentApplicationId,
}: TCreatePortfolioProps) => {
    const [isResumeRequired, setResumeRequired] = useState(!!investmentApplicationId);
    const [state, dispatch] = useReducer(reducer, initialState());
    const { clientId } = useClientProfile();
    const memberId = useReduxSelector(memberIdSelector);
    const { getListItemByKey } = useGetListItemsByKey();
    const { t } = useTranslation();

    // Init Hooks
    useEffect(() => {
        sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    }, [state]);

    // Mix
    const {
        isLoadingProducts,
        groupedProducts,
        products,
        errorProducts,
        getProducts,
    } = useProductsList(clientId);

    const currentInvestmentApplicationSelector = useMemo(() => (state.applications[state.currentInvestmentApplicationId]), [state.applications, state.currentInvestmentApplicationId]);
    const contactGroupIdSelector = useMemo(() => currentInvestmentApplicationSelector?.contactGroupId, [currentInvestmentApplicationSelector?.contactGroupId]);

    const { data: contactGroups } = useGetContactGroups({
        contactId: clientId,
        isEnabled: !!contactGroupIdSelector,
    });

    const selectedContactGroupSelector = useMemo(() => (contactGroups?.find((item) => (item.groupId === currentInvestmentApplicationSelector?.contactGroupId))), [currentInvestmentApplicationSelector?.contactGroupId, contactGroups]);

    const sortedContactGroupUsers = useMemo(() => Object.entries(selectedContactGroupSelector?.owners || {})
        .sort((a, b) => +a - +b)
        .map(([key, value]) => ({
            id: key,
            name: value,
        })),
    [selectedContactGroupSelector]);

    const {
        data: currentClientData,
        isLoading: isClientDataLoading,
        error: clientDataError,
        refetch: clientDataRefetch,
    } = useGetContact({ contactId: sortedContactGroupUsers?.[0]?.id ?? clientId });

    const {
        data: jointClientData,
        isLoading: isJointClientDataLoading,
        error: jointClientDataError,
    } = useGetContact({ contactId: sortedContactGroupUsers?.[1]?.id });

    const targetInvestorProfileClientIdSelector = useMemo(() => state?.investorProfileClientId || currentClientData?.id, [state.investorProfileClientId, currentClientData?.id]);
    const { data: advisoryData, refetch: advisoryDataRefetch, isFetching: advisoryDataLoading } = usePostAdvisoryProcessData({
        contactGroupId: contactGroupIdSelector,
        contactId: targetInvestorProfileClientIdSelector,
        isEnabled: false,
    });

    // Selectors
    const containerIdSelector = global.sessionStorage.getItem(APPLICATION_CONTAINER_ID);
    const currentInvestmentDescriptionSelector = useMemo(() => (currentInvestmentApplicationSelector?.investmentDescription), [currentInvestmentApplicationSelector?.investmentDescription]);
    const currentSelectedStrategySelector = useMemo(() => (currentInvestmentApplicationSelector?.strategy), [currentInvestmentApplicationSelector?.strategy]);
    const currentRecommendedStrategySelector = useMemo(() => (currentInvestmentApplicationSelector?.recommendedStrategy), [currentInvestmentApplicationSelector?.recommendedStrategy]);
    const advisoryIdSelector = useMemo(() => (state.advisoryData?.id), [state.advisoryData?.id]);
    const advisoryDataSelector = useMemo(() => (state.advisoryData), [state.advisoryData]);
    const isAdvisoryProcessSelector = useMemo(() => (state.shouldInitiateAdvisoryProcess === 1), [state.shouldInitiateAdvisoryProcess]);
    const advisoryUploadedDocumentSelector = useMemo(() => state.uploadedAdvisoryDocument, [state.uploadedAdvisoryDocument]);
    const selectedProductGroupSelector = useMemo(
        () => groupedProducts?.find(({ id }) => ((currentInvestmentApplicationSelector?.investmentDescription?.productId === id) || (id === 999999 && PRODUCT_VALUES_FOR_3B?.includes(currentInvestmentApplicationSelector?.investmentDescription?.productId)))),
        [currentInvestmentApplicationSelector?.investmentDescription?.productId, groupedProducts],
    );
    const selectedProductSelector = useMemo(() => products?.find(({ id }) => (currentInvestmentApplicationSelector?.investmentDescription?.productId === id)), [currentInvestmentApplicationSelector?.investmentDescription?.productId, products]);
    const selectedProductGroupIdSelector = useMemo(() => (selectedProductGroupSelector?.id ?? -1), [selectedProductGroupSelector?.id]);
    const selectedProductIdSelector = useMemo(() => (selectedProductSelector?.id ?? -1), [selectedProductSelector?.id]);
    const selectedProductTypeSelector = useMemo(() => (selectedProductGroupSelector?.settings?.productKey?.toLowerCase()), [selectedProductGroupSelector?.settings?.productKey]);
    const selectedProductDescSelector = useMemo(() => (selectedProductSelector?.description), [selectedProductSelector?.description]);
    const isDependantOnAffiliatedWithPensionFundSelector = useMemo(() => (!!selectedProductGroupSelector?.settings?.isDependantOnAffiliatedWithPensionFund), [selectedProductGroupSelector?.settings?.isDependantOnAffiliatedWithPensionFund]);
    const is3bProductGroupSelector = useMemo(() => (
        (selectedProductTypeSelector === PRODUCTS.bbb)
        || (selectedProductSelector?.settings?.productKey?.toLowerCase() === PRODUCTS.bbb)
    ), [selectedProductTypeSelector, selectedProductSelector]);

    const isJointAccountSelector = useMemo(() => (selectedContactGroupSelector?.groupName === ContactGroupEnum.ja), [selectedContactGroupSelector]);
    const currentClientSelector = useMemo(() => (getMainClientData(currentClientData)), [currentClientData]);
    const jointClientSelector = useMemo(() => (getMainClientData(jointClientData)), [jointClientData]);
    const isPageLoadingSelector = isJointClientDataLoading || isClientDataLoading || isLoadingProducts;
    const pageErrorsSelector = useMemo(() => (
        jointClientDataError || clientDataError || errorProducts
    ),
    [jointClientDataError || clientDataError || errorProducts]);
    const targetInvestorProfileDFSClientIdSelector = useMemo(() => (
        state?.investorProfileClientId || currentClientData?.id
            ? genarateDFSClientId(state?.investorProfileClientId || currentClientData?.id)
            : undefined),
    [state.investorProfileClientId, currentClientData?.id]);

    // advisoty options
    const categoryOptions = getListItemByKey({ key: AdvisoryOptionTypesEnum.CATEGORY });
    const instituteOptions = getListItemByKey({ key: AdvisoryOptionTypesEnum.INSTITUTE });
    const strategyOptions = getListItemByKey({ key: AdvisoryOptionTypesEnum.STRATEGY });
    const realStatePropertyOptions = getListItemByKey({ key: AdvisoryOptionTypesEnum.REAL_STATE_PROPERTY });
    const { strategyAllocations } = useGetStrategyAllocations();

    const proxyContactId = useMemo(() => (Object?.keys(selectedContactGroupSelector?.owners || {})?.find(key => +key !== clientId)), [selectedContactGroupSelector?.owners]);

    useEffect(() => {
        if (isResumeRequired) {
            resumeShoppingCardAction();
        }
    }, [isResumeRequired]);
    // Actions
    const resumeShoppingCardAction = useCallback(async () => {
        try {
            const { data: applicationData } = await ServiceManager.customInvestmentService('getInvestmentApplication', [{ investmentApplicationId }]);

            const {
                productId,
                advisoryDocumentId,
                containerId,
            } = applicationData?.investmentDescription;

            const productList = await getProducts();
            const selectedProduct = productList?.find(({ id }) => (productId === id)) as any;
            const is3B = selectedProduct?.settings?.productKey?.toLowerCase() === PRODUCTS.bbb;

            global.sessionStorage.setItem(APPLICATION_CONTAINER_ID, containerId);

            const payload = {
                applicationData,
                shouldInitiateAdvisoryProcess: 2,
                is3B,
                advisoryData: undefined,
                advisoryProcessInProgress: false,
            };

            if (is3B) {
                if (advisoryDocumentId) {
                    const { data } = await ServiceManager.customAdvisoryServices(
                        'getAdvisory',
                        [{
                            id: advisoryDocumentId,
                        }],
                    );

                    payload.advisoryData = data;
                    payload.shouldInitiateAdvisoryProcess = 1;

                    if (data?.status === 2) {
                        payload.advisoryProcessInProgress = true;
                    }
                }
            }

            dispatch({
                type: 'resumeShoppingCard',
                payload,
            });
            setResumeRequired(false);
        } catch (error: any) {
            console.error(error);
            notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${error?.message}`, type: 'error' });
        }
    }, [investmentApplicationId]);
    const setSelectedProductIdAction = useCallback(
        (prodId: number) => {
            const prod = groupedProducts?.find(({ id }) => (prodId === id));

            dispatch({
                type: 'setProductData',
                payload: {
                    productExternalId: prod?.externalId,
                    productId: prodId,
                },
            });
        },
        [groupedProducts],
    );
    const lockNavigationAction = useCallback(() => dispatch({
        type: 'lockNavigation',
    }), []);
    const unlockNavigationAction = useCallback(() => dispatch({
        type: 'unlockNavigation',
    }), []);
    const setAdvisoryProcessAction = useCallback((isAdvisoryProcess: boolean) => dispatch({
        type: 'setAdvisoryProcess',
        payload: isAdvisoryProcess,
    }), []);
    const setAdvisoryDataAction = useCallback((data:AdvisoryResponse|undefined) => dispatch({
        type: 'setAdvisoryData',
        payload: data,
    }), []);
    const setUploadedAdvisoryDocumentAction = useCallback((document: DocumentType | null) => dispatch({
        type: 'setUploadedAdvisoryDocument',
        payload: document,
    }), []);
    const setAdvisoryProcessTypeAction = useCallback((advisoryProcessType: string) => dispatch({
        type: 'setAdvisoryProcessType',
        payload: advisoryProcessType,
    }), []);
    const setConnectionGroupIdAction = useCallback((connectionGroupId: string) => dispatch({
        type: 'setConnectionGroupId',
        payload: connectionGroupId,
    }), []);
    const setShouldInitiateAdvisoryProcessAction = useCallback((shouldInitiateAdvisoryProcess: number) => dispatch({
        type: 'setShouldInitiateAdvisory',
        payload: shouldInitiateAdvisoryProcess,
    }), []);
    const saveRecommendedStrategyAction = useCallback(({ data }) => {
        dispatch({
            type: 'saveRecommendedStrategy',
            payload: data,
        });
    }, []);
    const saveInvestorProfileClientAction = useCallback((contactId:number) => {
        dispatch({
            type: 'saveInvestorProfileClient',
            payload: contactId,
        });
    }, []);
    const saveCurrentInvestmentApplicationIdAction = useCallback((id: number | string) => {
        dispatch({
            type: 'saveCurrentInvestmentApplicationId',
            payload: {
                investmentApplicationId: id,
            },
        });
    }, []);
    const clearProductSetupAction = useCallback(() => {
        dispatch({
            type: 'clearProductSetup',
        });
    }, []);
    const startProductSetupAction = useCallback((id: number | string) => {
        dispatch({
            type: 'startProductSetup',
            payload: {
                investmentApplicationId: id,
            },
        });
    }, []);
    const saveInvestmentApplicationDataAction = useCallback(
        async ({ method, payload, additionalData = {} }:SaveInvestmentApplicationDataParams) => {
            try {
                const payloadByMethod = {
                    saveProductDetails: () => (payload),
                    savePersonalDetails: () => ({
                        investmentApplicationId: state.currentInvestmentApplicationId,
                        ...payload,
                    }),
                    saveStrategyData: () => ({
                        investmentApplicationId: state.currentInvestmentApplicationId,
                        ...payload,
                    }),
                    saveProductData: () => ({
                        investmentApplicationId: state.currentInvestmentApplicationId,
                        ...payload,
                    }),
                    saveAgentData: () => ({
                        investmentApplicationId: state.currentInvestmentApplicationId,
                        ...payload,
                    }),
                    saveKYCDoneByContainer:
                        () => ({
                            id: containerIdSelector,
                            ...payload,
                        }),
                    saveKYCDone:
                        () => ({
                            investmentApplicationId: state.currentInvestmentApplicationId,
                            ...payload,
                        }),
                    saveIdUploadDone:
                        () => ({
                            investmentApplicationId: state.currentInvestmentApplicationId,
                            ...payload,
                        }),
                    saveWithdrawalData:
                        () => ({
                            investmentApplicationId: state.currentInvestmentApplicationId,
                            ...payload,
                        }),
                };

                const requestPayload = payloadByMethod?.[method]?.() ?? payload;
                const response = await ServiceManager.customInvestmentService(method, [requestPayload]);

                if (response?.status === 200 && method !== 'saveKYCDoneByContainer') {
                    dispatch({
                        type: 'saveInvestmentApplicationData',
                        payload: { ...response?.data, ...additionalData },
                    });
                }

                return response;
            } catch (error:any) {
                console.error(error);
                notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${error?.message}`, type: 'error' });

                return error;
            }
        },
        [
            clientId,
            memberId,
            selectedProductGroupSelector?.id,
            selectedProductGroupSelector?.externalId,
            state.currentInvestmentApplicationId,
            containerIdSelector,
        ],
    );

    const removeInvestmentApplicationDataAction = useCallback(({ id }: {id: number | string}) => {
        dispatch({
            type: 'removeInvestmentApplicationData',
            payload: {
                investmentApplicationId: id,
            },
        });
    }, []);

    const advisoryProcessInProgressAction = useCallback((payload: boolean) => {
        dispatch({
            type: 'setAdvisoryProcessInProgress',
            payload,
        });
    }, []);

    const startAdvisoryProcessAction = useCallback(async () => {
        if (!state?.advisoryData?.id) {
            const response = await advisoryDataRefetch();

            setAdvisoryDataAction(response?.data?.data);
        } else {
            setAdvisoryDataAction(state?.advisoryData);
        }
        advisoryProcessInProgressAction(true);
    }, [state?.advisoryData]);

    const saveAdvisoryDataAction = useCallback(
        async ({ key, payload }: {key: string, payload: keyof AdvisoryMetadataResponse}) => {
            try {
                const response:any = await ServiceManager.customAdvisoryServices('updateAdvisoryProcessData', [{
                    advisoryId: advisoryIdSelector,
                    key,
                    payload,
                }]);

                if (response?.status === 200) {
                    dispatch({
                        type: 'setAdvisoryData',
                        payload: response?.data,
                    });
                }

                return { status: response.status };
            } catch (error:any) {
                notification.open({ content: `${t('contactGroups.somethingWentWrong')} ${error?.message}`, type: 'error' });

                return { error };
            }
        },
        [advisoryIdSelector],
    );

    const {
        isZivZicAzp, isZic, isAzp, isZiv, isZifd, isZicAzp, isPk, isZivZifd, isZivAzp, isJA, isAzpI, isAzpD, isAzpAlt,
    } = useThreeBProductType({ product: selectedProductSelector });

    // Effects
    useEffect(() => {
        (async () => getProducts())();
    }, [getProducts]);

    return (
        <ConnectionsManager clientId={clientId}>
            <CreatePortfolioContext.Provider value={{
                state: {
                    ...state,
                },
                actions: {
                    startAdvisoryProcessAction,
                    resumeShoppingCardAction,
                    setSelectedProductIdAction,
                    setAdvisoryProcessAction,
                    setAdvisoryProcessTypeAction,
                    setConnectionGroupIdAction,
                    saveInvestmentApplicationDataAction,
                    saveInvestorProfileClientAction,
                    saveRecommendedStrategyAction,
                    setShouldInitiateAdvisoryProcessAction,
                    lockNavigationAction,
                    setAdvisoryDataAction,
                    setUploadedAdvisoryDocumentAction,
                    unlockNavigationAction,
                    saveAdvisoryDataAction,
                    clientDataRefetchAction: clientDataRefetch,
                    saveCurrentInvestmentApplicationIdAction,
                    startProductSetupAction,
                    clearProductSetupAction,
                    advisoryProcessInProgressAction,
                    removeInvestmentApplicationDataAction,
                },
                selectors: {
                    isZivZicAzp,
                    isZic,
                    isAzp,
                    isZiv,
                    isZifd,
                    isZicAzp,
                    isPk,
                    isZivZifd,
                    isZivAzp,
                    isJA,
                    isAzpI,
                    isAzpD,
                    isAzpAlt,
                    containerIdSelector,
                    targetInvestorProfileClientIdSelector,
                    targetInvestorProfileDFSClientIdSelector,
                    currentRecommendedStrategySelector,
                    currentSelectedStrategySelector,
                    currentInvestmentApplicationSelector,
                    currentInvestmentDescriptionSelector,
                    advisoryDataSelector,
                    advisoryIdSelector,
                    isAdvisoryProcessSelector,
                    is3bProductGroupSelector,
                    selectedProductDescSelector,
                    currentClientSelector,
                    jointClientSelector,
                    selectedProductTypeSelector,
                    selectedProductSelector,
                    selectedProductGroupSelector,
                    isJointAccountSelector,
                    selectedProductGroupIdSelector,
                    selectedProductIdSelector,
                    contactGroupIdSelector,
                    isPageLoadingSelector,
                    pageErrorsSelector,
                    advisoryUploadedDocumentSelector,
                    isDependantOnAffiliatedWithPensionFundSelector,
                    currentClientPersonalDetailsDataSelector: currentClientData,
                    jointClientPersonalDetailsDataSelector: jointClientData,
                    advisoryOptions: {
                        category: categoryOptions,
                        institute: instituteOptions,
                        strategy: strategyOptions,
                        realEstateProperties: realStatePropertyOptions,
                    },
                    strategyAllocations,
                    proxyContactId,
                    advisoryProcessInProgressSelector: state?.advisoryProcessInProgress,
                    isAdvisoryDataLoading: advisoryDataLoading,
                },
            }}
            >
                <Preloader isLoading={isResumeRequired}>
                    {children}
                </Preloader>
            </CreatePortfolioContext.Provider>
        </ConnectionsManager>
    );
};
CreatePortfolioManager.LOCAL_STORAGE_KEY = LOCAL_STORAGE_KEY;

export const useCreatePortfolio = (): CreatePortfolioAPI => useContext(CreatePortfolioContext);
