import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import moment from 'moment';
import ServiceManager from 'services/ServiceManager';
import {memberIdSelector} from 'redux-store/auth/authSelectors';
import {useThreeBProductType} from 'hooks/isThreeBProduct';
import {adaptAbisory} from '../adapters/advisor';
import {isValid, validate} from '../valdation';
import {useFormatting} from '../../../../../../../locale';

export const useBaseAgentInformation = ({newPortfolio}) => {
    const { i18n: { language }, t } = useTranslation();
    const {getFormattedNumber} = useFormatting();
    const memberId = useSelector(memberIdSelector);
    const [isAdvisorDataLoading, setAdvisorDataLoading] = useState(false);
    const [issueCommissionSelected, setIssueCommissionSelected] = useState(null);
    const [pbSelected, setPBSelected] = useState('');
    const [selectedGeneralAgency, setSelectedGeneralAgency] = useState('');
    const [vstNumber, setVSTNumber] = useState('');
    const [advisorData, setAdvisorData] = useState([]);
    const [isContinuesValidation, setContinuesValidation] = useState(false);
    const [generalAgencies, setGeneralAgencies] = useState(null);
    const [agentsList, setAgentsList] = useState([{id: '', label: t('position.pleaseSelect')}]);
    const [selectedAgent, setSelectedAgent] = useState('');
    const [searchAgentsInProgress, setSearchAgentsInProgress] = useState(false);
    const {isZic, isPk} = useThreeBProductType(newPortfolio);

    const issueCommissionChange = useCallback((item) => {
        setIssueCommissionSelected(item);
    }, [setIssueCommissionSelected]);
    const pbChange = useCallback((item) => {
        setPBSelected(item);
    }, [setPBSelected]);

    const changeVSTNumber = useCallback((e) => {
        setVSTNumber(e?.target?.value || e);
    });

    const validationResult = useMemo(() => (
        validate({
            commission: issueCommissionSelected,
            pbNumber: pbSelected,
            vstNumber,
            generalAgency: selectedGeneralAgency,
            agentInfo: selectedAgent,
            isZic,
        }, t)
    ), [issueCommissionSelected, pbSelected, vstNumber, validate, selectedGeneralAgency, selectedAgent]);

    const isValidFormData = useMemo(() => (
        isValid(validationResult)
    ), [validationResult]);

    const validation = useMemo(() => (isContinuesValidation ? validationResult : {}), [isContinuesValidation, validationResult]);

    const modelPortfolioSettings = useMemo(() => (JSON.parse(newPortfolio?.modelPortfolio?.Attributes ?? '{}')), [newPortfolio?.modelPortfolio?.Attributes]);

    const issueCommissionOptions = useMemo(() => (
        (modelPortfolioSettings?.issueComission ?? []).map((item) => ({
            label: getFormattedNumber(item?.label, { maximumFractionDigits: 1, minimumFractionDigits: 1 }),
            value: String(item.value),
        }))
    ), [modelPortfolioSettings?.issueComission]);

    useEffect(() => {
        // For Zurich MA set no commission
        if ((newPortfolio?.applicationData?.investmentDescription?.personalDetails?.zurichEmployee || isPk) && issueCommissionOptions) {
            const zeroCommission = issueCommissionOptions.find(f => f.label === '0.0')?.value;

            setIssueCommissionSelected(zeroCommission);
        }
    }, [issueCommissionOptions, newPortfolio?.applicationData?.investmentDescription?.personalDetails?.zurichEmployee, isPk]);

    const formatPhoneNumber = useCallback((phoneNumber) => {
        if (!phoneNumber) {
            return '-';
        }

        return phoneNumber.indexOf('|') > -1 ? `+${phoneNumber.split('|').join(' ')}` : phoneNumber;
    }, []);

    const getGeneralAgencies = useCallback(async () => {
        try {
            const cachedData = JSON.parse(global.localStorage.getItem('GLOBAL_AGENCIES') || null);
            const hasCacheExpired = !cachedData?.created || moment().diff(moment(cachedData?.created), 'hours', true) >= 24; // refresh cache every 24 hours

            if (cachedData && !hasCacheExpired) {
                setGeneralAgencies(cachedData.data);
            } else {
                const {data} = await ServiceManager.customInvestmentService('getGeneralAgencies', []);
                const payload = {
                    data,
                    created: new Date(),
                };

                global.localStorage.setItem('GLOBAL_AGENCIES', JSON.stringify(payload));

                setGeneralAgencies(data);
            }
        } catch (err) {
            //
        }
    }, []);

    const getAgentInformation = useCallback(async (filter) => {
        try {
            setSearchAgentsInProgress(true);

            const {data} = await ServiceManager.customMembersService('getMembers', [{filter}]);

            setAgentsList(data);
        } finally {
            setSearchAgentsInProgress(false);
        }
    }, []);

    const handleSearchAgents = useCallback(async (filter) => {
        await getAgentInformation(filter);
    }, [getAgentInformation]);

    useEffect(() => {
        const agentInformation = newPortfolio?.applicationData?.investmentDescription?.agentInformation ?? newPortfolio?.agentInformation;

        if (agentInformation) {
            setVSTNumber(agentInformation?.vstNumber);
            if (issueCommissionOptions?.length) {
                const selectIssueCommissionCandidate = String(agentInformation?.issueCommission);

                if (issueCommissionOptions.find((item) => (item?.value === selectIssueCommissionCandidate))) setIssueCommissionSelected(selectIssueCommissionCandidate);
            }

            setPBSelected(agentInformation?.pbNumber ?? agentInformation?.pbSelected);
            setSelectedGeneralAgency(agentInformation?.agencyId ?? agentInformation?.selectedGeneralAgency);
        }
    }, [
        newPortfolio,
        setIssueCommissionSelected,
        issueCommissionOptions,
        agentsList,
    ]);

    useEffect(() => {
        if (agentsList?.length > 1) {
            setSelectedAgent(newPortfolio?.applicationData?.memberId || memberId);
        }
    }, [agentsList, newPortfolio?.applicationData?.memberId, memberId]);

    useEffect(() => {
        (async () => getGeneralAgencies())();
    }, [getGeneralAgencies]);

    useEffect(() => {
        (async () => getAgentInformation(''))();
    }, [getAgentInformation]);

    useEffect(() => {
        (async () => {
            try {
                setAdvisorDataLoading(true);
                const memberProfileResponse = await ServiceManager.member(
                    'getMembersProfile',
                    [memberId, {language: 'en'}],
                );

                setAdvisorData(adaptAbisory(memberProfileResponse?.data, t));
            } finally {
                setAdvisorDataLoading(false);
            }
        })();
    }, [language]);

    return {
        t,
        setContinuesValidation,
        validation,
        isValid: isValidFormData,
        formatPhoneNumber,
        productDesc: newPortfolio?.product?.description,
        isLoading: isAdvisorDataLoading,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        vstNumber,
        changeVSTNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    };
};
