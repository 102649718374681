import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {Loader, NumberInput, Title} from 'ui-library';
import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import InfoBox from 'components/common/InfoBox';

import './PensionSituation.css';

const getShowFormOptions = (t) => [{
    label: t('PensionSituationForm.choose.yes'),
    id: '2',
}, {
    label: t('PensionSituationForm.choose.no'),
    id: '1',
}];

const PensionSituation:FC<{datasource: () => any}> = ({datasource}) => {
    const {t} = useTranslation();

    const {
        data: {
            total3AInvestments, totalVBInvestments, totalPension, showTotalPension,
        }, isLoading, errors, control, showForm, setShowForm,
    } = datasource() || {};

    if (isLoading) return <Loader />;

    return (
        <div className="pension-situation-form-comp" style={{maxWidth: 720}}>
            <div>
                <div className="mt-40">
                    <ButtonRadioElement
                        data-cy="specify-pension-situation"
                        value={showForm ? '2' : '1'}
                        nameData="specify-pension-situation"
                        items={getShowFormOptions(t)}
                        passData={(value: any) => {
                            setShowForm(value === '2');
                        }}
                    />
                </div>

                {showForm && (
                    <div>
                        <div className="pension-situation-form flex flex-wrap">
                            <div className="tied-pension-provision-zurich mt-40">
                                <Title title={t('PensionSituationForm.TiedPensionProvistion.Zurich.title')} type={2} className="font-normal">
                                    {t('PensionSituationForm.TiedPensionProvistion.Zurich.title')}
                                </Title>

                                <div className="tied-pension-plans">
                                    <Title type={3} title={t('PensionSituationForm.TiedPensionProvistion.Zurich.VestedBenifits.title')}>
                                        <strong>{t('PensionSituationForm.TiedPensionProvistion.Zurich.VestedBenifits.title')}</strong>
                                    </Title>
                                    <p>{totalVBInvestments ? `CHF ${totalVBInvestments}` : '-'}</p>
                                </div>

                                <div className="tied-pension-plans">
                                    <Title type={3} title={t('PensionSituationForm.TiedPensionProvistion.Zurich.Pillar3a.title')}>
                                        <strong>{t('PensionSituationForm.TiedPensionProvistion.Zurich.Pillar3a.title')}</strong>
                                    </Title>
                                    <p>{total3AInvestments ? `CHF ${total3AInvestments}` : '-'}</p>
                                </div>

                                <p className="tied-pension-plans--auto-loaded-info mb-24">
                                    <strong>{t('PensionSituationForm.TiedPensionProvistion.Zurich.Pillar3a.autoLoadedInfo')}</strong>
                                </p>

                                <Controller
                                    name="lifeInsurance3aVb"
                                    control={control}
                                    render={({field}) => (
                                        <NumberInput
                                            {...field}
                                            placeholder=""
                                            thousandSeparator="'"
                                            decimalSeparator="."
                                            label={t('lifeInsurance3aVb.label.value')}
                                            data-cy="lifeInsurance3aVb"
                                            error={errors?.lifeInsurance3aVb?.message}
                                            prefix="CHF "
                                            textRight
                                        />
                                    )}
                                />
                            </div>

                            <div className="tied-pension-provision-third-party mt-40">
                                <Title type={2} className="font-normal" title={t('PensionSituationForm.TiedPensionProvistion.ThirdParty.title')}>
                                    {t('PensionSituationForm.TiedPensionProvistion.ThirdParty.title')}
                                </Title>
                                <div className="tied-pension-provision-form">
                                    <Controller
                                        name="otherVbAmount"
                                        control={control}
                                        render={({field}) => (
                                            <NumberInput
                                                {...field}
                                                placeholder=""
                                                thousandSeparator="'"
                                                decimalSeparator="."
                                                label={t('otherVbAmount.label.value')}
                                                data-cy='"otherVbAmount'
                                                error={errors?.otherVbAmount?.message}
                                                prefix="CHF "
                                                textRight
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="otherPillar3aAmount"
                                        control={control}
                                        render={({field}) => (
                                            <NumberInput
                                                {...field}
                                                placeholder=""
                                                thousandSeparator="'"
                                                decimalSeparator="."
                                                label={t('otherPillar3aAmount.label.value')}
                                                data-cy="otherPillar3aAmount"
                                                error={errors?.otherPillar3aAmount?.message}
                                                prefix="CHF "
                                                textRight
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="pensionFund"
                                        control={control}
                                        render={({field}) => (
                                            <NumberInput
                                                {...field}
                                                placeholder=""
                                                thousandSeparator="'"
                                                decimalSeparator="."
                                                label={t('pensionFund.label.value')}
                                                data-cy="pensionFund"
                                                error={errors?.pensionFund?.message}
                                                prefix="CHF "
                                                textRight
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        {showTotalPension && (
                            <InfoBox
                                title={t('PensionSituationForm.info.totalPenstion')}
                                value={totalPension}
                            />
                        )}
                    </div>
                )}

            </div>
        </div>
    );
};

export default PensionSituation;
