/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Button, Icon, Infobox, Loader, Modal, NumberInput, Select, Title,
} from 'ui-library';
import {
    ICON_EDIT_MINIMAL,
    ICON_TYPE_BIN,
    ICON_TYPE_CLOSE_SLIM,
} from 'ui-library/components/Icon';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import { THREEBTYPES } from 'constants/constants';
import Error from 'components/AtomicStructure/atoms/Error';
import BuySellToggle from 'ui-library/components/BuySellToggle/BuySellToggle';
import { StaticField } from '../../../ui-library/components/StaticField/StaticField';
import { ExistingProductsRowProps } from './ShoppingCartProductRow.types';
import useExistingProducts from './useExistingProducts';
import './ShoppingCartProductRow.css';

const ExistingProductRow: FC<ExistingProductsRowProps> = (props) => {
    const { i18n: { language }, t } = useTranslation();
    const {
        product: portfolio,
        refetchExistingPlans,
        advisoryDocumentId,
        remainingInvestmentAmount,
    } = props;

    const {
        modalStatus,
        setModalStatus,
        showEditIcon,
        handleEditStrategy,
        strategy,
        setStrategy,
        onChangeStrategy,
        modelPortfoliosLoading,
        strategyOptions,
        onChangeInvestmentPlan,
        investment,
        changeInvestment,
        changeBuySell,
        NO_EXCLUSION,
        strategyOptionsForZIVV,
        selectedStrategyIdState,
        setSelectedStrategyIdState,
        optionPreferenceStock,
        preferenceStockState,
        setPreferenceStockState,
        isCapital,
        preferenceInclusion,
        optionPreferenceInclusion,
        setPreferenceInclusionState,
        optionPreferenceExclusion,
        preferenceExclusionState,
        setPreferenceExclusionState,
        optionExclusionSubstitute,
        exclusionSubstituteState,
        setExclusionSubstituteState,
        isZIC,
        selectedProduct,
        selectedModelPortfolio,
        handleDiscard3bApplication,
        changeExisting3bPlanResponseIsPending,
    } = useExistingProducts({
        portfolio, language, t, refetchExistingPlans, advisoryDocumentId,
    });

    const isValidInvestmentSell = useMemo(() => investment?.isSell && investment?.amount > +(portfolio?.amount as number), [portfolio?.amount, investment]);

    return (
        <>
            <div className="product-row existing-product-row">
                <div className="product-col">
                    <div className="configured-col">
                        <StaticField
                            className="product_name"
                            label={t('ShoppingCart.ProductRow.ProductName')}
                            value={portfolio?.existingProduct?.name as string}
                        />

                        <div className="product_strategy">
                            <StaticField
                                label={t('ShoppingCart.ProductRow.Strategy')}
                                value={portfolio?.newStrategy as string}
                                Icon={(showEditIcon && portfolio?.existingProduct?.externalId !== THREEBTYPES.zic) && (
                                    <Button
                                        type="link"
                                        onClick={() => handleEditStrategy({
                                            open: true,
                                            product: portfolio?.existingProduct?.externalId as string,
                                            field: 'strategy',
                                        })}
                                        className="edit-icon-button"
                                    >
                                        <Icon
                                            type={ICON_EDIT_MINIMAL}
                                            size={20}
                                        />
                                    </Button>
                                )}
                            />

                            {portfolio?.changedStrategy && (
                                <div className="total-amount-new">
                                    <span>
                                        {t('ShoppingCart.ProductRow.CurrentStrategy')}
                                        {': '}
                                        {portfolio?.oldStrategy}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="product_investment">
                            <StaticField
                                label={t('ShoppingCart.ProductRow.Investment')}
                                value={`${portfolio?.changedAmount ? (portfolio?.isBuyTransaction) ? '+' : '-' : ''} CHF ${applyThousandSeparator((portfolio?.changedAmount ? Math.abs(portfolio?.changedAmount)?.toString() : portfolio?.amount?.toString()), '\'')}`}
                                Icon={showEditIcon && (
                                    <Button
                                        type="link"
                                        onClick={() => setModalStatus({
                                            open: true,
                                            product: portfolio?.existingProduct?.externalId as string,
                                            field: 'investment',
                                        })}
                                        className="edit-icon-button"
                                    >
                                        <Icon
                                            type={ICON_EDIT_MINIMAL}
                                            size={20}
                                        />
                                    </Button>
                                )}
                            />

                            {portfolio?.changedAmount && (
                                <div className="total-amount-new">
                                    <span>
                                        {t('ShoppingCart.ProductRow.CurrentAmount')}
                                        {': '}
                                        {`CHF ${applyThousandSeparator(portfolio?.amount?.toString(), '\'')}`}
                                    </span>
                                </div>
                            )}
                        </div>
                        {(portfolio?.editable && portfolio?.pendingCEPRequest)
                        && (
                            <Infobox className="shopping-cart-pending-cep-info">
                                {t('ShoppingCart.ProductRow.LockedProductMessage')}
                            </Infobox>
                        )}
                        {portfolio?.edited && (
                            <Button
                                type="link"
                                onClick={handleDiscard3bApplication}
                            >
                                <Icon
                                    type={ICON_TYPE_BIN}
                                    size={20}
                                />
                                {' '}
                                {t('ShoppingCart.ProductRow.DiscardChanges')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            {modalStatus.open && modalStatus?.field === 'strategy' && (
                <Modal
                    width={610}
                    visible
                    onCancel={() => setModalStatus({
                        open: false,
                    })}
                    onOk={onChangeStrategy}
                    okText={t('confirmation.Save')}
                    cancelText={t('confirmation.cancel')}
                    closable
                    borderRadius
                    okButtonProps={{ disabled: !strategy || (strategy === portfolio?.modelPortfolioId), loading: changeExisting3bPlanResponseIsPending }}
                    closeIcon={(
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                            color={undefined}
                            ariaLabel={undefined}
                            component={undefined}
                            className={undefined}
                        />
                    )}
                    className="change-strategy-modal"
                >
                    <Title type={2} className="mb-24">
                        {portfolio?.existingProduct?.externalId}
                        {' - '}
                        {t('ShoppingCart.Modal.Form.Label.ChangeStrategy')}
                    </Title>
                    <div className="edit-strategy-modal--current-strategy-info mb-24">
                        {t('ShoppingCart.Modal.Form.Label.CurrentStrategy')}
                        {' '}
                        {portfolio?.oldStrategy}
                    </div>

                    <div className="edit-strategy-form">
                        {modelPortfoliosLoading ? <Loader />
                            : (
                                <Select
                                    label={t('ShoppingCart.Modal.Form.Label.NewStrategy')}
                                    options={strategyOptions}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="change-strategy"
                                    value={strategy}
                                    required
                                    onChange={setStrategy}
                                />
                            )}
                    </div>
                </Modal>
            )}

            {modalStatus.open && modalStatus?.field === 'investment' && (
                <Modal
                    width={610}
                    visible
                    onCancel={() => setModalStatus({
                        open: false,
                    })}
                    onOk={onChangeInvestmentPlan}
                    okText={t('confirmation.Save')}
                    cancelText={t('confirmation.cancel')}
                    closable
                    borderRadius
                    okButtonProps={{ disabled: !investment?.amount || isValidInvestmentSell, loading: changeExisting3bPlanResponseIsPending || false }}
                    closeIcon={(
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                            color={undefined}
                            ariaLabel={undefined}
                            component={undefined}
                            className={undefined}
                        />
                    )}
                    className="change-strategy-modal"
                >
                    <Title type={2} className="mb-24">
                        {t('ShoppingCart.Modal.Form.Label.ChangeInvestmentAmount')}
                    </Title>
                    <div className="edit-strategy-modal--current-strategy-info mb-24">
                        {t('ShoppingCart.Modal.Form.Label.InvestedAmount')}
                        {' '}
                        {applyThousandSeparator(portfolio?.amount?.toString(), '\'')}
                    </div>

                    <div className="edit-investment mb-20">
                        <BuySellToggle offText={t('ShoppingCart.BuySellToggle.Label.Buy')} onText={t('ShoppingCart.BuySellToggle.Label.Sell')} error={null} onChange={changeBuySell} defaultChecked={isZIC} value={isZIC || !portfolio?.isBuyTransaction || investment?.isSell} disabled={isZIC} />
                        <NumberInput
                            id="investment"
                            placeholder=""
                            thousandSeparator="'"
                            decimalSeparator="."
                            label=""
                            prefix="CHF "
                            value={investment?.amount}
                            required
                            error={null}
                            onChange={changeInvestment}
                        />
                    </div>
                    {isValidInvestmentSell && <Error>{t('ShoppingCart.InvestmentAmount.ToHighError')}</Error>}
                </Modal>
            )}

            {modalStatus.open && modalStatus?.product === THREEBTYPES.zivv && modalStatus?.field === 'strategy' && (
                <Modal
                    width={610}
                    visible={modalStatus.open && modalStatus?.product === THREEBTYPES.zivv && modalStatus?.field === 'strategy'}
                    onCancel={() => setModalStatus({
                        open: false,
                    })}
                    onOk={onChangeStrategy}
                    okText={t('confirmation.Save')}
                    cancelText={t('confirmation.cancel')}
                    okButtonProps={{ loading: changeExisting3bPlanResponseIsPending || false }}
                    closable
                    borderRadius
                    closeIcon={(
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                        />
                    )}
                    className="change-strategy-modal"
                >
                    <Title type={2} className="mb-24">
                        {t('ShoppingCart.Modal.Form.Label.ChangeZIVVStrategy')}
                    </Title>
                    <div className="edit-strategy-modal--current-strategy-info mb-24">
                        {t('ShoppingCart.Modal.Form.Label.CurrentStrategy')}
                        {' '}
                        {selectedModelPortfolio?.Name}
                    </div>

                    <form className="edit-strategy-form">
                        {modelPortfoliosLoading ? <Loader /> : (
                            <Select
                                label={t('ShoppingCart.Modal.Form.Label.BaseStrategy')}
                                options={strategyOptionsForZIVV}
                                placeholder={t('position.pleaseSelect')}
                                data-cy="change-strategy"
                                value={selectedStrategyIdState}
                                required
                                onChange={setSelectedStrategyIdState}
                            />
                        )}
                        <Select
                            label={t('ShoppingCart.Modal.Form.Label.PreferenceStocks')}
                            options={optionPreferenceStock}
                            placeholder={t('position.pleaseSelect')}
                            data-cy="preference-stocks"
                            required
                            value={preferenceStockState?.toString() || null}
                            onChange={setPreferenceStockState}
                        />
                        {isCapital ? (
                            <Select
                                data-cy="preference-inclusion"
                                value={preferenceInclusion}
                                options={optionPreferenceInclusion || []}
                                label={t('portfolio.createNewPortfolio.selectStrategy.preferenceInclusion')}
                                placeholder={t('portfolio.createNewPortfolio.agentInformation.selectPlaceholder')}
                                onChange={setPreferenceInclusionState}
                            />
                        ) : (
                            <>
                                <Select
                                    label={t('ShoppingCart.Modal.Form.Label.PreferenceExclusion')}
                                    options={optionPreferenceExclusion}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="preference-execution"
                                    required
                                    value={preferenceExclusionState?.toString() || null}
                                    onChange={setPreferenceExclusionState}
                                />
                                <Select
                                    label={t('ShoppingCart.Modal.Form.Label.SubstituteForExclusion')}
                                    options={optionExclusionSubstitute}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="substitute-for-execution"
                                    value={exclusionSubstituteState?.toString() || null}
                                    onChange={setExclusionSubstituteState}
                                    disabled={preferenceExclusionState && preferenceExclusionState === NO_EXCLUSION}
                                />
                            </>
                        )}

                        <div className="configured-col">
                            <Title type={4}>
                                <strong>
                                    {t('ShoppingCart.Modal.Form.Label.TargetStrategy')}
                                    :
                                </strong>
                                <div className="mt-12">
                                    {selectedProduct?.Name}
                                </div>
                            </Title>
                        </div>

                        <div className="target-strategy-note">
                            {t('ShoppingCart.Modal.Form.Label.ChangeZIVVStrategy.Note')}
                        </div>
                    </form>
                </Modal>
            )}
        </>
    );
};

export default ExistingProductRow;
