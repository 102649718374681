import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { number, object, ObjectSchema } from 'yup';
import { useTranslation } from 'react-i18next';

import {
    PRODUCT_VALUES_FOR_3A_VB,
    PRODUCT_VALUES_FOR_3A_VB_ENUM,
} from 'constants/constants';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
} from 'datasource/CreatePortfolio';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import { getValidationMessage } from 'utils/validation';
import { getPortfolioStatus } from 'constants/portfolioStatuses';
import { useGetJointPortfolios } from 'hooks/rest/useGetJointPortfolios';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useClientProfile } from 'prodivers/clientProfile';
import { formatFloatingNumber } from 'utils';
import { PensionSituationFormData } from './usePensionSituation.types';

export const usePensionSituation = () => {
    const { t } = useTranslation();
    const { clientId } = useClientProfile();

    const {
        advisoryIdSelector, isJointAccountSelector, advisoryDataSelector, proxyContactId,
    } = useCreatePortfolioSelectors();
    const { saveAdvisoryDataAction } = useCreatePortfolioActions();

    const { data: jointAccountPortfolios } = useGetJointPortfolios({
        clientId,
        proxyId: parseInt(proxyContactId || '', 10),
    });

    const getPortfolios = useCallback(
        () => jointAccountPortfolios
            ?.filter(
                (portfolio) => getPortfolioStatus({ portfolioStatusId: portfolio?.portfolioStatusId }) === 'active'
                        && [...PRODUCT_VALUES_FOR_3A_VB]?.includes(
                            portfolio?.product?.id,
                        ),
                // && (isJointAccountSelector
                //     ? portfolio?.holderNames?.length > 1
                //     : portfolio?.holderNames?.length < 2),
            )
            ?.map((p) => ({
                portfolioId: p?.id,
                productId: p?.product?.id,
                value: p?.currentValue || 0,
            })),
        [jointAccountPortfolios, isJointAccountSelector],
    );

    const [continueButtonLoading, setContinueButtonLoading] = useState<boolean>(false);
    const portfolios = getPortfolios() || [];

    const pillar3aAmount = useMemo(
        () => portfolios
            ?.filter(
                (portfolio) => portfolio?.productId === PRODUCT_VALUES_FOR_3A_VB_ENUM['3A'],
            )
            .reduce((acc, curr) => acc + (curr.value || 0), 0),
        [portfolios],
    );

    const vbAmount = useMemo(
        () => portfolios
            ?.filter(
                (portfolio) => portfolio?.productId === PRODUCT_VALUES_FOR_3A_VB_ENUM.VB,
            )
            .reduce((acc, curr) => acc + (curr.value || 0), 0),
        [portfolios],
    );

    const initialPensionSituation = useMemo(() => advisoryDataSelector?.metadata?.pensionSituation || null, [advisoryDataSelector?.metadata?.pensionSituation]);

    const [showForm, setShowForm] = useState<boolean>();

    useEffect(() => {
        setShowForm(!!initialPensionSituation);
    }, [initialPensionSituation]);

    const {
        mandatoryError, numberError,
    } = getValidationMessage();

    const numberInputValidation = number(mandatoryError)
        .typeError(numberError)
        .nullable(true, mandatoryError);

    const schema: ObjectSchema<PensionSituationFormData> = useMemo(() => object().shape({
        otherVbAmount: numberInputValidation,
        otherPillar3aAmount: numberInputValidation,
        pensionFund: numberInputValidation,
        lifeInsurance3aVb: numberInputValidation,
    }), [t]);

    const resolver = useYupValidationResolver(schema);

    const {
        control, handleSubmit, formState: { errors, isDirty }, watch,
    } = useForm({
        resolver,
        defaultValues: initialPensionSituation,
    });

    const data = watch();

    const total3AInvestments = useMemo(() => (pillar3aAmount ? applyThousandSeparator(`${pillar3aAmount.toFixed(2)}`, '’') : null),
        [pillar3aAmount, applyThousandSeparator]);

    const totalVBInvestments = useMemo(
        () => (vbAmount
            ? applyThousandSeparator(`${vbAmount.toFixed(2)}`, '’')
            : null),
        [vbAmount],
    );

    const totalPension = useMemo(() => {
        const totalValue = (+pillar3aAmount || 0) + (+vbAmount || 0) + (+data?.otherPillar3aAmount || 0) + (+data?.otherVbAmount || 0) + (+data?.pensionFund || 0) + (+data?.lifeInsurance3aVb || 0);

        return formatFloatingNumber(totalValue);
    }, [pillar3aAmount, vbAmount, data]);

    const showTotalPension = useMemo(() => !!pillar3aAmount || !!vbAmount || !!data?.otherPillar3aAmount || !!data?.otherVbAmount || !!data?.pensionFund || !!data?.lifeInsurance3aVb, [pillar3aAmount, vbAmount, data]);

    const saveData = async (dta: PensionSituationFormData) => {
        const payload = { ...dta, pillar3aAmount, vbAmount };

        if (advisoryIdSelector) {
            const result: any = await saveAdvisoryDataAction({
                key: 'pension-situation',
                payload,
            });

            if (result?.status === 200) {
                setContinueButtonLoading(false);
                unlockNavigationAction();
                stepComplete();
            }
        }
    };

    const onSubmit = useCallback(async (dta: PensionSituationFormData) => {
        if (showForm && (isDirty || pillar3aAmount || vbAmount)) {
            setContinueButtonLoading(true);

            await saveData(dta);
        } else {
            unlockNavigationAction();
            stepComplete();
        }
    }, [isDirty, showForm, pillar3aAmount, vbAmount]);

    const { stepComplete, unlockNavigationAction } = usePortfolioProcessNavigation({
        saveOnContinue: useCallback(handleSubmit(onSubmit), [onSubmit]),
        continueInProgress: continueButtonLoading,
        enableAutoStepComplete: !isDirty,
    });

    return {
        data: {
            total3AInvestments,
            totalVBInvestments,
            totalPension,
            showTotalPension,
        },
        isLoading: false,
        isJoint: isJointAccountSelector,
        formData: data,
        control,
        errors,
        showForm,
        setShowForm,
    };
};
