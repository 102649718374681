import React, { FC } from 'react';
import { Icon } from 'ui-library';
import { ICON_TRIANGLE_OUTLINE_GRAY } from 'ui-library/components/Icon';
import { WarningProps } from './Warning.types';
import './Warning.css';

const Warning:FC<WarningProps> = (props) => {
    const {
        iconType = ICON_TRIANGLE_OUTLINE_GRAY, iconSize = 24, showIcon = true, children,
    } = props;

    return (
        <div className="warning-comp">
            {showIcon && <Icon type={iconType} size={iconSize}/>}
            {children}
        </div>
    );
};

export default Warning;
