/* eslint-disable no-nested-ternary */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import {
    Button, Icon, Modal, NumberInput,
} from 'ui-library';
import PieChartWithLegend from 'components/PieChartWIthLegend/PieChartWithLegend';
import { ICON_EDIT_OUTLINE, ICON_TYPE_WARNING } from '../../ui-library/components/Icon';
import { DEFAULT_APP_CURRENCY } from '../../constants/constants';
import Error from '../../components/AtomicStructure/atoms/Error';
import { usePortfolioProcessNavigation } from '../../hooks/helpers/usePortfolioProcessNavigation';
import './AssetsOverview.css';

type AssetsOverviewProps = {
    datasource: () => any;
}

const AssetsOverview:FC<AssetsOverviewProps> = ({ datasource }) => {
    usePortfolioProcessNavigation({ preventNavigationLock: true });
    const { t } = useTranslation();

    const {
        data,
        formData,
        control,
        errors,
        showModal,
        closeModal,
        totalLiquidityForInvestment,
        totalPensionSituation,
        totalAmountOfOtherAssets,
        submitAmountChange,
        isLoading,
        error,
        setShowModal,
        formatValue,
        handleInvestmentAmount,
        showSecurityAllocationChart,
    } = datasource();

    const isDesiredAmountLargerThenAvailable = formData?.desiredInvestmentAmount && totalLiquidityForInvestment && +formData?.desiredInvestmentAmount > totalLiquidityForInvestment;

    const desiredAmountModal = useMemo(() => (
        <Modal
            visible={showModal}
            destroyOnClose
            onCancel={closeModal}
            title={t('assetsOverview.desiredAmountModalTitle')}
            width={520}
            okText={t('assetsOverview.saveAmount')}
            cancelText={t('clientDashboard.cancel')}
            confirmLoading={isLoading}
            onOk={handleInvestmentAmount}
            className="desired-amount-modal"
            isLoading={isLoading}
        >
            <section>
                <Controller
                    name="desiredInvestmentAmount"
                    control={control}
                    render={({ field: { onChange, ...field } }) => (
                        <NumberInput
                            {...field}
                            error={errors.desiredInvestmentAmount?.message}
                            disabled={isLoading}
                            thousandSeparator="'"
                            decimalSeparator="."
                            value={data?.desiredInvestmentAmount}
                            onChange={onChange}
                            label={t('assetsOverview.investmentAmount')}
                            prefix={`${DEFAULT_APP_CURRENCY} `}
                            textRight
                            required
                        />
                    )}
                />
                {isDesiredAmountLargerThenAvailable && (
                    <div className="modal-warning">
                        <Icon type={ICON_TYPE_WARNING} size={20}/>
                        <p>{t('assetsOverview.investmentAmountHigher')}</p>
                    </div>
                )}
            </section>
        </Modal>
    ), [showModal, t, isLoading, submitAmountChange, closeModal, errors, totalLiquidityForInvestment, formData?.desiredInvestmentAmount, data?.desiredInvestmentAmount]);

    if (error) {
        return <Error>{error.message}</Error>;
    }

    return (
        <>
            <section className="assets-overview">
                <h3 className="title surplus-title">{t('assetsOverview.liquidityForInvestment')}</h3>
                <section className="liquidity">
                    <p>{t('assetsOverview.liquidity')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.totalLiquidity, '+')}</p>
                    <p>{t('assetsOverview.assetInflow')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.expectedAssetInflow, '+')}</p>
                    <p>{t('assetsOverview.expenses')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.plannedExpenses, '-')}</p>
                    <p>{t('assetsOverview.credits')}</p>
                    <p className="amount">{formatValue(data?.liquidityForInvestment?.loans, data?.liquidityForInvestment?.loans ? '-' : '')}</p>
                    {totalLiquidityForInvestment > 0 ? <p className="total">{formatValue(totalLiquidityForInvestment)}</p> : ''}
                </section>
                <section className="desired-amount">
                    <h4>{t('assetsOverview.desiredAmount')}</h4>
                    <Button type="link" onClick={() => setShowModal(true)}>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Icon type={ICON_EDIT_OUTLINE} size={24} color="white"/>
                    </Button>
                    <p className="total">{(data?.desiredInvestmentAmount || totalLiquidityForInvestment) ? formatValue(data?.desiredInvestmentAmount ?? totalLiquidityForInvestment, ((data?.desiredInvestmentAmount ? data?.desiredInvestmentAmount < 0 : totalLiquidityForInvestment < 0) ? '-' : '')) : '0.00'}</p>
                </section>

                <h3 className="pension-title">{t('assetsOverview.pension')}</h3>
                <h3 className="pension-title-total">{totalPensionSituation ? formatValue(totalPensionSituation) : ''}</h3>
                <h3 className="asset-title">{t('assetsOverview.other')}</h3>
                <h3 className="asset-title-total">{totalAmountOfOtherAssets ? formatValue(totalAmountOfOtherAssets) : ''}</h3>
                <div className="section-overview pension-situation">
                    <section>
                        <h4>{t('assetsOverview.pilar')}</h4>
                        <p>{t('assetsOverview.zurich')}</p>
                        <p className="amount">{data?.pensionSituation?.pillar3aAmount ? formatValue(data?.pensionSituation?.pillar3aAmount) : '-'}</p>
                        <p>{t('assetsOverview.thirdParty')}</p>
                        <p className="amount">{formatValue(data?.pensionSituation?.otherPillar3aAmount)}</p>
                    </section>
                    <section>
                        <h4>{t('assetsOverview.vestedBenefits')}</h4>
                        <p>{t('assetsOverview.zurich')}</p>
                        <p className="amount">{data?.pensionSituation?.vbAmount ? formatValue(data?.pensionSituation?.vbAmount) : '-'}</p>
                        <p>{t('assetsOverview.thirdParty')}</p>
                        <p className="amount">{formatValue(data?.pensionSituation?.otherVbAmount)}</p>
                    </section>
                    <section>
                        <h4>{t('assetsOverview.lifeInsurance')}</h4>
                        <p>{t('assetsOverview.zurich')}</p>
                        <p className="amount">{formatValue(data?.pensionSituation?.lifeInsuranceZurich)}</p>
                        <p>{t('assetsOverview.thirdParty')}</p>
                        <p className="amount">{formatValue(data?.pensionSituation?.lifeInsuranceThirdParties)}</p>
                    </section>
                </div>

                <div className="section-overview other-asset">
                    <section>
                        <h4>{t('assetsOverview.depots')}</h4>
                        <p className="single-amount">{data?.otherAssets?.investDepots ? formatValue(data?.otherAssets?.investDepots) : '-'}</p>
                    </section>
                    <section>
                        <h4>{t('assetsOverview.properties')}</h4>
                        <p className="single-amount">{formatValue(data?.otherAssets?.properties)}</p>
                    </section>
                    <section>
                        <h4>{t('assetsOverview.thirdPartyAssets')}</h4>
                        <p className="single-amount">{formatValue(data?.otherAssets?.thirdPartyAssets)}</p>
                    </section>
                </div>

            </section>
            {showSecurityAllocationChart && data?.securityAllocations?.length > 0 ? (
                <section className="assets-overview assets-overview--chart-wrapper">
                    <section>
                        <div className="title">
                            <h3>{t('assetsOverview.allocations')}</h3>
                            <span>{t('assetsOverview.allocationsDesc')}</span>
                        </div>
                        <PieChartWithLegend chartData={data?.securityAllocations || []} className="assets-overview-chart" />
                    </section>
                </section>
            ) : null}
            {showModal && desiredAmountModal}
        </>
    );
};

export default AssetsOverview;
