import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useThreeBProductType } from 'hooks/isThreeBProduct';
import { AdditionalData, InvestorProfileHook } from 'widgets/InvestorProfile/types/InvestorProfileHook';
import { useCreatePortfolioActions, useCreatePortfolioSelectors, useCreatePortfolioState } from '../CreatePortfolio';

export const useInvestorProfile = (): InvestorProfileHook => {
    const { i18n: { language } } = useTranslation();

    const {
        jointClientSelector,
        currentClientSelector,
        selectedProductSelector,
        targetInvestorProfileDFSClientIdSelector,
        isJointAccountSelector,
        currentInvestmentApplicationSelector,
        isAdvisoryProcessSelector,
    } = useCreatePortfolioSelectors();

    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();

    const {
        saveInvestmentApplicationDataAction,
        saveRecommendedStrategyAction,
        saveAdvisoryDataAction,
    } = useCreatePortfolioActions();

    const {
        isPk,
    } = useThreeBProductType({ product: selectedProductSelector });

    const saveAdditionalData = useCallback(async ({ clientId: mClientId, clientRiskToAccept, response }: AdditionalData) => {
        if (mClientId === clientRiskToAccept) {
            const additionalData: any = { recommendedStrategy: response };

            saveRecommendedStrategyAction({
                data: additionalData,
            });
        }
    }, [language, currentInvestmentApplicationId]);

    return {
        isJointAccount: isJointAccountSelector,
        isPk,
        saveAdditionalData,
        childClientName: jointClientSelector?.name,
        targetClientName: currentClientSelector?.name,
        targetDFSClientId: targetInvestorProfileDFSClientIdSelector,
        clientAge: currentClientSelector?.age,
        isAdvisoryProcessSelector,
        saveAdvisoryDataAction,
    };
};
