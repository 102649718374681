import {useQuery} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { notification } from 'ui-library';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';

export const useGetCustomService = ({
    service, api, payload, enabled = false,
} : {service: string, api: string, payload: any, enabled: boolean}) => {
    const {t} = useTranslation();
    const getData = async () => {
        try {
            return await SM[service](api, [payload]);
        } catch (error: any) {
            notification.open({content: `${t('contactGroups.somethingWentWrong')} ${error.message}`, type: 'error'});

            return null;
        }
    };

    const {
        data, error, isLoading, isFetching, refetch,
    } = useQuery({
        queryKey: [`${'getData_'}${api}`],
        enabled,
        retry: false,
        queryFn: () => getData(),
    });

    return {
        data: data || null,
        statusCode: data?.status,
        error: error ? new ServerError(error) : error,
        isLoading: isLoading || isFetching,
        refetch,
    };
};
