import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
    CheckboxGroup, Label as LabelUI, NumberInput, RadioButtonGroup,
} from 'ui-library';
import ButtonRadioElement from '../AtomicStructure/atoms/ButtonRadioElement';
import ConfirmationDisclaimer from '../ConfirmationDisclaimer/ConfirmationDisclaimer';
import './QuestionnaireQuestion.css';

const setOptions = (
    options = [], disabled = false, name = Math.ceil(Math.random() * 100000),
) => options.map(({ Id, Text }) => ({
    value: Id,
    label: Text,
    disabled,
    name,
}));

const setButtonRadioOptions = (options) => options.map(({ Id, Text }) => ({ label: Text, id: Id }));

const setCheckBoxOptions = (
    options = [],
    defaultValue = [],
    disableDefaultValue,
) => options.map(({ Id, Text, Order }) => ({
    value: Id,
    label: Text,
    disabled: disableDefaultValue && (defaultValue || []).find((i) => i === Id),
    position: Order,
}));

const QuestionnaireQuestion = (props) => {
    const {
        data, value, error, onChange, defaultValue, disableDefaultValue, radioRenderComponent, dataAttrCy, disabled,
    } = props;

    const onValueChange = useCallback((val) => {
        onChange({ questionId: data.Id, value: val });
    }, [data.Id, onChange]);

    const renderCheckbox = (isConditional) => (
        <fieldset className="question-group">
            {data && <legend className="Form-control-label">{data.Text}</legend>}
            <CheckboxGroup
                value={value}
                options={
                    setCheckBoxOptions(data.Answers, defaultValue, disableDefaultValue)
                }
                error={error}
                onChange={(val) => onValueChange(val)}
                size="big"
                defaultValue={defaultValue}
                isConditional={isConditional}
                isInQuestionnaire
            />
        </fieldset>
    );
    const question = useMemo(() => {
        switch (data?.Layout) {
            case 'ButtonRadio':
                return (
                    <ButtonRadioElement
                        label={data.Text}
                        error={error}
                        items={setButtonRadioOptions(data.Answers)}
                        value={value}
                        passData={(id) => onValueChange(id)}
                        dataAttrCy={dataAttrCy}
                    />
                );
            case 'Radio':
                return radioRenderComponent
                    ? radioRenderComponent({
                        val: value,
                        desc: data.Text,
                        error,
                        options: setOptions(data.Answers),
                        onChange: (val) => onValueChange(val),
                    })
                    : (
                        <fieldset>
                            {data && <legend className="Form-control-label">{data.Text}</legend>}
                            {/* <LabelUI label={data?.Text} /> */}
                            <RadioButtonGroup
                                value={value}
                                options={setOptions(data.Answers, disabled, data.Id)}
                                error={error}
                                onChange={(e) => onValueChange(+e.target.value)}
                                {...{ disabled }}
                            />
                        </fieldset>
                    );
            case 'Checkbox':
                return renderCheckbox(false);
            case 'ConditionalCheckbox':
                return renderCheckbox(true);
            case 'Toggle':
                return (
                    <fieldset className="question-group">
                        {data && <legend className="Form-control-label">{data.GroupDescription}</legend>}
                        <ConfirmationDisclaimer
                            value={value}
                            onChange={(val) => onValueChange(val ? data.Answers?.[0]?.Id : undefined)}
                            error={error}
                            agreeOnText={data.Answers?.[0]?.Text}
                            text={data.Text}
                        />
                    </fieldset>
                );
            default:
                if (data?.Type === 'FreeText') {
                    return (
                        <>
                            <LabelUI label={data?.Text} />
                            <NumberInput
                                value={value}
                                error={error}
                                onChange={(val) => onValueChange(val)}
                            />
                        </>
                    );
                }

                return null;
        }
    }, [
        data.Answers, data.Id,
        data.Layout, data.Type, error,
        value, data?.GroupDescription,
        dataAttrCy, disabled,
        onValueChange,
    ]);

    return (
        <div className="questionnaire-question">
            {question}
        </div>
    );
};

QuestionnaireQuestion.propTypes = {
    data: PropTypes.shape({
        Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        Text: PropTypes.string,
        Type: PropTypes.string,
        Layout: PropTypes.string,
        Answers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            Text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })),
        GroupDescription: PropTypes.string,
    }),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.number),
    ]),
    error: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.arrayOf(PropTypes.number),
    disableDefaultValue: PropTypes.bool,
};

QuestionnaireQuestion.defaultProps = {
    data: undefined,
    value: undefined,
    error: undefined,
    onChange: () => {},
    defaultValue: [],
    disableDefaultValue: false,
};

export default QuestionnaireQuestion;
