/* eslint-disable no-nested-ternary */
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Infobox, Loader, Title,
} from 'ui-library';
import InvestmentAmountOptions from 'components/ShoppingCart/InvestmentAmountOptions/InvestmentAmountOptions';
import ExistingProductRow from 'components/ShoppingCart/ShoppingCartProductRow/ExistingProductRow';
import NewProductRow from 'components/ShoppingCart/ShoppingCartProductRow/NewProductRow';
import AddButton from 'components/common/AddButton';
import { OverviewStepProps } from './ShoppingCard.types';
import './ShoppingCard.css';

const ShoppingCard: FC<OverviewStepProps> = ({ datasource }) => {
    const { t } = useTranslation();

    const {
        formData,
        advisoryId,
        desiredInvestmentAmount,
        existingPortfolios,
        totalInvestmentAmount,
        remainingInvestmentAmount,
        fields,
        control,
        errors,
        handleDeleteRow,
        handleAddProduct,
        productOptions3B,
        saveNewProduct,
        newProductsLoading,
        cancelInProgress,
        handleConfig,
        is3bProductGroupSelector,
        selectedProductSelector,
        isAdvisoryProcessSelector,
        currentClientSelector,
        saveCurrentInvestmentApplicationIdAction,
        continueDisabled,
        refetchExistingPlans,
        advisoryDocumentId,
        advisoryDataLoading,
    } = datasource();

    const addNewButtonDisabled = useMemo(() => {
        if ((isAdvisoryProcessSelector && remainingInvestmentAmount <= 0) || (!is3bProductGroupSelector && fields?.length > 0) || (!selectedProductSelector && !is3bProductGroupSelector)) {
            return true;
        }

        return false;
    }, [advisoryId, remainingInvestmentAmount, is3bProductGroupSelector, fields, selectedProductSelector]);

    return (
        <div className="shopping-card new-portfolio agent-info">
            <Title type={2} className="mb-16">
                {t('ShoppingCart.portfolioPersonalOverview')}
            </Title>
            {advisoryDataLoading ? <Loader /> : isAdvisoryProcessSelector ? (
                <InvestmentAmountOptions
                    desiredInvestmentAmount={desiredInvestmentAmount}
                    remainingInvestmentAmount={remainingInvestmentAmount}
                />
            ) : null}

            <div className="products-on-cart">
                <Title type={4}>
                    {t('ShoppingCart.newProductsTitle')}
                </Title>
                {newProductsLoading ? <Loader /> : fields?.length ? fields?.map((item, index) => (
                    <NewProductRow
                        control={control}
                        errors={errors}
                        key={item?.investmentApplicationId || index}
                        index={index}
                        product={item}
                        configured={item?.isConfigured}
                        handleDeleteRow={handleDeleteRow}
                        productOptions={productOptions3B}
                        saveNewProduct={saveNewProduct}
                        formData={formData?.products?.[index]}
                        cancelInProgress={cancelInProgress}
                        handleConfig={handleConfig}
                        selectedProductSelector={selectedProductSelector}
                        is3bProductGroupSelector={is3bProductGroupSelector}
                        currentClientSelector={currentClientSelector}
                        saveCurrentInvestmentApplicationIdAction={saveCurrentInvestmentApplicationIdAction}
                        continueDisabled={continueDisabled}
                    />
                )) : null}
                <AddButton
                    onClick={handleAddProduct}
                    label={
                        t('ShoppingCart.addNewProductButton.label')
                    }
                    className="mb-32"
                    disabled={addNewButtonDisabled}
                />
            </div>

            {(is3bProductGroupSelector && !isAdvisoryProcessSelector && totalInvestmentAmount > 200000 && totalInvestmentAmount <= 250000) ? (
                <Infobox warning showIcon={false} className="mb-32">
                    {t('ShoppingCart.AmountOver200k.Message')}
                </Infobox>
            ) : null}

            {(is3bProductGroupSelector && totalInvestmentAmount > 250000) ? (
                <Infobox warning showIcon={false} className="mb-32">
                    {t('3b.performance.proofOfAssetsWarning')}
                </Infobox>
            ) : null}

            {/* For later */}

            {existingPortfolios?.loading ? <Loader /> : (isAdvisoryProcessSelector && existingPortfolios?.data?.length > 0)
                ? (
                    <div className="products-on-cart mt-30">
                        <Title type={4}>
                            {t('ShoppingCart.existing3bProductsTitle')}
                        </Title>

                        {existingPortfolios?.data?.map((product, index) => (
                            <ExistingProductRow
                                key={product?.id || index}
                                product={product}
                                refetchExistingPlans={refetchExistingPlans}
                                advisoryDocumentId={advisoryDocumentId}
                                remainingInvestmentAmount={remainingInvestmentAmount}
                            />
                        ))}
                    </div>
                ) : null}
        </div>
    );
};

export default ShoppingCard;
