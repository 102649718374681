import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Title } from 'ui-library';
import { useOptionList } from 'hooks/useOptionList';

import ButtonRadioElement from 'components/AtomicStructure/atoms/ButtonRadioElement';
import ProductSummaryItem
    from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/pages/Finalization/components/ProductSummaryItem/ProductSummaryItem';
import {
    EXTERNAL_PRODUCTS, OPTION_LIST, PRODUCTS, THREEBTYPES,
} from 'constants/constants';
import { PAYMENT_PERIODS } from 'pages/ClientOverview/pages/Portfolios/pages/CreateNewPortfolio/utils';
import { useFinalization } from './hooks/useFinalization';

import './Finalization.css';

function Finalization() {
    const { dfsClientId } = useParams();

    const {
        t,
        appsList,
        loadingAppsByContainer,
        languagesOption,
        passData,
        error,
        data,
    } = useFinalization({
        dfsClientId,
    });

    const {
        lists: [optionWithdrawalPeriodicity],
    } = useOptionList([OPTION_LIST.WithdrawalPeriodicity]);

    const getPeriodicityLabel = useCallback((periodicity) => Object.values(PAYMENT_PERIODS)
        .find(f => f.id === periodicity)?.label, []);

    const getWithdrawalFrequencyLabel = useCallback((frequency) => optionWithdrawalPeriodicity?.find(f => f.id === frequency)?.label, [optionWithdrawalPeriodicity]);

    return (
        <div className="new-portfolio quality-check">
            <Title type={2}>{t('portfolio.createNewPortfolio.opening.finalizeTheApplication')}</Title>
            <div className="form">
                <ButtonRadioElement
                    data-cy="documentLanguage"
                    label={t('portfolio.createNewPortfolio.opening.documentLanguage')}
                    items={languagesOption}
                    error={error?.documentLanguage}
                    passData={passData}
                    value={data?.documentLanguage}
                    nameData="documentLanguage"
                    required
                />
            </div>
            <Title
                className="product-summary-title"
                type={2}
            >
                {t('portfolio.createNewPortfolio.opening.productSummary')}
            </Title>
            <div className="product-summary-data">
                {loadingAppsByContainer && <Loader />}
                {appsList?.length > 0 ? appsList?.filter(application => application?.status !== 2)?.map(app => {
                    const combinedApp = app?.applicationData ?? app;

                    return (
                        <ProductSummaryItem
                            key={combinedApp.investmentApplicationId}
                            oneTimeInvestment={combinedApp?.investmentDescription?.productConfiguration?.initialInvestment}
                            periodicPayment={combinedApp?.investmentDescription?.productConfiguration?.periodicalPaymentAmount}
                            frequency={t(getPeriodicityLabel(combinedApp?.investmentDescription?.productConfiguration?.periodicity))}
                            category={combinedApp.investmentDescription.productConfiguration?.rubrik}
                            transfer={combinedApp.investmentDescription.productConfiguration?.yearlyPayments}
                            pensionSchema={combinedApp.investmentDescription.productConfiguration?.pensionScheme}
                            isFullTransfer={combinedApp.investmentDescription.productConfiguration?.isFullTransfer}
                            strategy={combinedApp?.strategy || app.strategy}
                            isPk={combinedApp.investmentDescription?.productExternalId === THREEBTYPES.pk}
                            isZic={combinedApp.investmentDescription?.productExternalId === THREEBTYPES.zic}
                            is3a={combinedApp.investmentDescription?.productExternalId === PRODUCTS.aaa}
                            isFz={[EXTERNAL_PRODUCTS.fz.toUpperCase(), EXTERNAL_PRODUCTS.fzp.toUpperCase()].includes(combinedApp.investmentDescription?.productExternalId?.toUpperCase())}
                            withdrawal={combinedApp.investmentDescription?.withdrawal ? {
                                amount: combinedApp.investmentDescription.withdrawal?.withdrawalAmount,
                                frequency: getWithdrawalFrequencyLabel(combinedApp.investmentDescription.withdrawal?.periodicity),
                                start: combinedApp.investmentDescription.withdrawal?.startDate,
                                plannedEndDate: combinedApp.investmentDescription.withdrawal?.plannedEndDate,
                                iban: combinedApp.investmentDescription.paymentInstruction?.iban,
                                holder: `${combinedApp.investmentDescription.paymentInstruction?.firstName} ${combinedApp.investmentDescription.paymentInstruction?.lastName}`,
                                bank: `${combinedApp.investmentDescription.paymentInstruction?.bankName}, ${combinedApp.investmentDescription.paymentInstruction?.zipCity} ${combinedApp.investmentDescription.paymentInstruction?.city}`,
                            } : null}
                        />
                    );
                }) : null}
            </div>
        </div>
    );
}

export default Finalization;
