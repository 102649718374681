/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-array-index-key */
import React, {
    FC, useCallback, useMemo, useState,
} from 'react';
import {
    Button, Icon, Loader, Modal, Title,
} from 'ui-library';
import Divider from 'ui-library/components/Divider';
import { ICON_ARROW_RIGHT_TRENDY, ICON_TYPE_BIN } from 'ui-library/components/Icon';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import { useTranslation } from 'react-i18next';
import { ApplicationStatus } from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import classnames from 'classnames';
import { ApplicationTypesEnum, SingleApplicationRowProps } from './Applications.types';
import { DocStatus } from '../DocBox/DocBox.types';
import DocBox from '../DocBox';
import Warning from '../Warning';
import { ApplicationDocumentType } from '../AdvisorySection/AdvisorySection.types';
import { useApplicationDetails } from '../../../datasource/useApplicationDetails';

const SingleApplicationRow:FC<SingleApplicationRowProps> = (props) => {
    const { t } = useTranslation();
    const {
        id,
        status,
        type,
        typeKey,
        productName,
        applicationId,
        createdDate,
        validTill,
        advisor,
        createdBy,
        investmentAmount,
        submitted,
        submittedBy,
        depotNumber,
        investedAmount,
        amountToBeSold,
        purchaseAmount,
        currentStrategy,
        documents,
        partialSaleOrLiquidate,
        withTransferDoc,
        rubrik,
        canBeDeleted,
        isSentCase,
    } = props?.data;

    const [cancelInProgress, setCancelInProgress] = useState(false);
    const [isApplicationInSubmit, setApplicationInSubmit] = useState(false);
    const { isAdvisoryDocReadyForSubmission, cancelApplication, submitApplication } = useApplicationDetails();
    const submitButtonDisabledStatus = useMemo(() => documents?.find((doc: ApplicationDocumentType) => doc.status !== DocStatus.ReadyToSubmit) || !isAdvisoryDocReadyForSubmission || isApplicationInSubmit, [documents, isAdvisoryDocReadyForSubmission, isApplicationInSubmit]);
    const showWarningStatus = useMemo(() => !documents?.find((doc: ApplicationDocumentType) => doc.status !== DocStatus.ReadyToSubmit) && !isAdvisoryDocReadyForSubmission, [documents, isAdvisoryDocReadyForSubmission]);
    const allDocumentsGenerated = useMemo(() => !documents?.find((doc: ApplicationDocumentType) => doc.status === DocStatus.ReadyToGenerate), [documents]);
    const showSubmitButton = useMemo(() => (
        typeKey !== ApplicationTypesEnum.BUY
        && !partialSaleOrLiquidate
        && allDocumentsGenerated
        && !(typeKey === ApplicationTypesEnum.NEW && props?.isAdvisoryExpired)
        && status !== ApplicationStatus.canceled
        && status !== ApplicationStatus.completed
        && status !== ApplicationStatus.submitted
    ), [props?.isAdvisoryExpired, type, allDocumentsGenerated, status]);

    const documentsClassNames = classnames('single-application--grid-col', {
        'doc-wrapper-transfer': isSentCase,
    });
    const submitionClassNames = classnames('single-application--grid-col', {
        'doc-submit-wrapper-transfer': isSentCase,
    });

    const onCancelClick = useCallback(() => {
        Modal.confirm({
            title: t('dashboard.overviewClientApplications.confirmTitle'),
            content: t('dashboard.overviewClientApplications.confirmMessage'),
            okText: cancelInProgress ? <Loader /> : t('dashboard.overviewClientApplications.confirmOk'),
            cancelText: t('dashboard.overviewClientApplications.confirmCancel'),
            onOk: async () => {
                setCancelInProgress(true);
                await cancelApplication({ applicationId: id });
                setCancelInProgress(false);
            },
        });
    }, [cancelApplication, id]);

    const handleSubmitClick = useCallback(async () => {
        setApplicationInSubmit(true);
        try {
            await submitApplication({ applicationId: id, planType: type });
        } finally {
            setApplicationInSubmit(false);
        }
    }, [submitApplication, id]);

    return (
        <div className={`single-application-section ${typeKey === ApplicationTypesEnum.NEW && props?.isAdvisoryExpired ? 'single-application-section--disabled' : ''}`}>
            <Title type="3">
                <strong>{type}</strong>
                {' '}
                {productName}
                {rubrik ? ` <${rubrik}>` : ' '}
                {canBeDeleted && (
                    <div className="ml-auto">
                        <Button
                            className="application-details-header--delete-btn"
                            type="link"
                            onClick={onCancelClick}
                        >
                            <Icon size={16} className="mt-3 mr-10" type={ICON_TYPE_BIN}/>
                            {t('applicationDetailsHeader.Delete')}
                        </Button>
                    </div>
                )}
            </Title>

            <div className="application--row">
                <div className="application-info-grid">
                    {/* Columns only shown if application type is NEW || CHANGE */}
                    {[ApplicationTypesEnum.NEW, ApplicationTypesEnum.CHANGE].includes(typeKey) && (
                        <>
                            <div className="application-info-grid-col">
                                <Title type="5">
                                    {t('ApplicationDetails.AdvisorySection.ProductApplications.ApplicationID')}
                                </Title>
                                {applicationId}
                            </div>
                            <div className="application-info-grid-col">
                                <Title type="5">
                                    {t('ApplicationDetails.AdvisorySection.ProductApplications.Created')}
                                </Title>
                                {createdDate}
                            </div>
                            <div className="application-info-grid-col">
                                <Title type="5">
                                    {t('ApplicationDetails.AdvisorySection.ValidTill')}
                                </Title>
                                {validTill}
                            </div>
                            <Divider direction="vertical" />
                        </>
                    )}

                    <div className="application-info-grid-col">
                        <Title type="5">
                            {t('ApplicationDetails.AdvisorySection.Advisor')}
                        </Title>
                        {advisor}
                    </div>
                    <div className="application-info-grid-col">
                        <Title type="5">
                            {t('ApplicationDetails.AdvisorySection.ProductApplications.CreatedBy')}
                        </Title>
                        {createdBy}
                    </div>
                    <Divider direction="vertical" />

                    {/* Columns only shown if application type is NEW */}
                    {typeKey === ApplicationTypesEnum.NEW && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.InvestmentAmount')}
                            </Title>
                            CHF
                            {' '}
                            {investmentAmount ? applyThousandSeparator(investmentAmount.toString(), ',') : 0}
                        </div>
                    )}

                    {/* Columns only shown if application type is CHANGE || SELL || BUY */}
                    {[ApplicationTypesEnum.CHANGE, ApplicationTypesEnum.SELL, ApplicationTypesEnum.BUY].includes(typeKey) && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.DepotNumber')}
                            </Title>
                            {depotNumber}
                        </div>
                    )}

                    {/* Columns only shown if application type is CHANGE */}
                    {typeKey === ApplicationTypesEnum.CHANGE && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.CurrentStrategy')}
                            </Title>
                            {currentStrategy}
                        </div>
                    )}

                    {/* Columns only shown if application type is SELL || BUY */}
                    {[ApplicationTypesEnum.SELL, ApplicationTypesEnum.BUY].includes(typeKey) && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.InvestedAmount')}
                            </Title>
                            CHF
                            {' '}
                            {investedAmount ? applyThousandSeparator(investedAmount.toString(), ',') : 0}
                        </div>
                    )}

                    {/* Columns only shown if application type is SELL */}
                    {typeKey === ApplicationTypesEnum.SELL && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.ToBeSold')}
                            </Title>
                            CHF
                            {' '}
                            {amountToBeSold ? applyThousandSeparator(amountToBeSold.toString(), ',') : 0}
                        </div>
                    )}

                    {/* Columns only shown if application type is BUY */}
                    {typeKey === ApplicationTypesEnum.BUY && (
                        <div className="application-info-grid-col">
                            <Title type="5">
                                {t('ApplicationDetails.AdvisorySection.ProductApplications.PurchaseAmount')}
                            </Title>
                            CHF
                            {' '}
                            {purchaseAmount ? applyThousandSeparator(purchaseAmount.toString(), ',') : 0}
                        </div>
                    )}
                    <Divider direction="vertical" />

                    <div className="application-info-grid-col">
                        <Title type="5">
                            {t('ApplicationDetails.AdvisorySection.ProductApplications.Submitted')}
                        </Title>
                        {submitted || '-'}
                    </div>
                    <div className="application-info-grid-col">
                        <Title type="5">
                            {t('ApplicationDetails.AdvisorySection.ProductApplications.SubmittedBy')}
                        </Title>
                        {submittedBy || '-'}
                    </div>
                </div>
                <div className="visible-ipad mb-16">
                    {showWarningStatus && (
                        <Warning>
                            {t('ApplicationDetails.AdvisorySection.ProductApplications.WarningToUploadDoc')}
                        </Warning>
                    )}
                </div>
                {typeKey === ApplicationTypesEnum.SELL && (
                    <div>
                        <p>
                            {t('ApplicationDetails.AdvisorySection.ProductApplications.DownloadAndSend')}
                        </p>

                        {/* @ts-ignore */}
                        {/*  <Checkbox checked={false} label="I confirm to have submitted the document to <kudimail>" /> */}
                    </div>
                )}
                <div className="application-footer-grid">
                    <div className={documentsClassNames}>
                        {documents?.map(
                            (document, index) => (
                                <DocBox
                                    isSentCase={isSentCase}
                                    key={`${document?.documentName?.replaceAll(' ', '_')}_${index}`}
                                    type={document.type}
                                    isSent={document?.isSent}
                                    uploadStatus={document?.status}
                                    documentName={document?.documentName}
                                    applicationId={document?.applicationId}
                                    documentId={document?.documentId}
                                />
                            ),
                        )}
                    </div>
                    {showSubmitButton && (
                        <div className={submitionClassNames}>
                            <div className="single-application--action">
                                <div className="visible-desktop">
                                    {showWarningStatus && (
                                        <Warning>
                                            {t('ApplicationDetails.AdvisorySection.ProductApplications.WarningToUploadDoc')}
                                        </Warning>
                                    )}
                                </div>
                                <Button type="primary" disabled={submitButtonDisabledStatus} onClick={handleSubmitClick}>
                                    {isApplicationInSubmit ? <Loader/> : (
                                        <>
                                            {t('ApplicationDetails.AdvisorySection.ProductApplications.Submit')}
                                            <Icon className="ml-8" type={ICON_ARROW_RIGHT_TRENDY} size={24} />
                                        </>
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SingleApplicationRow;
