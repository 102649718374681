import {
    ApplicationDetailsActionTypes,
    DocTypes,
    TApplicationDetailsManagerState,
} from 'datasource/useApplicationDetails/ApplicationDetailsManager.types';
import { DocStatus } from '../../components/ApplicationDetails/DocBox/DocBox.types';

export const docLoadingEntity = {
    documentName: '',
    status: DocStatus.Loading,
    type: DocTypes.GENERATE,
};

export const updateTypeToActionMap = {
    [DocTypes.APPLICATION]: ApplicationDetailsActionTypes.UPDATE_APPLICATION_DOC,
    [DocTypes.BASIC_AGREEMENT]: ApplicationDetailsActionTypes.UPDATE_BASIC_AGREEMENT_DOC,
};

export const initialState = (): TApplicationDetailsManagerState => ({
    proofOfAssetDocuments: [docLoadingEntity],
    basicAgreementDocuments: [docLoadingEntity],
    applicationsData: [],
});
