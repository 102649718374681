import get from 'lodash/get';
import { isEqual, isObject, transform } from 'lodash';
import {
    SINCE_INCEPTION,
    YEARS1,
    YEARS3,
    YEARS5,
    YTD,
} from 'filters/investementPerformanceFilters';
import { roundSmallestError } from './rounding';
import {
    formatDate, subtractUnits, getFirstDayOfYear, getTimestamp,
} from './datetime';

export const checkNullData = (data) => (
    data === undefined || data === null || data === '' || data.length === 0
);

export const genarateDFSClientId = (value) => (
    `DFS${'0'.repeat(10 - String(value).length) + value}`
);

export const getDataOrAriaProps = (props) => {
    const retProps = {};

    Object.keys(props).forEach((key) => {
        if ((key.substr(0, 5) === 'data-'
                || key.substr(0, 5) === 'aria-'
                || key === 'role'
                || key === 'name')
            && key.substr(0, 7) !== 'data-__') {
            retProps[key] = props[key];
        }
    });

    return retProps;
};

export const getClientId = (value) => (
    /DFS[0-9]{10}/g.test(value) ? Number(value.split('DFS')[1]) : undefined
);

export const getExternalId = (clientId) => (Number(clientId) + 10000000).toString();

export const createUniqueKey = (index, label) => index + encodeURIComponent(label);
export const createObjUniqueKey = (data) => Object.values(data).join();

export const getClassNames = (...args) => args
    .map((arg) => ((arg && typeof arg === 'object') ? Object.keys(arg).filter((item) => arg[item]) : arg))
    .reduce((acc, val) => acc.concat(val), [])
    .filter((item) => item).join(' ');

export const getParamsFromUrl = (param) => {
    const url = new URL(global.window.location.href);

    return url.searchParams.get(param);
};

export const transformOptions = (options, { labelKey, valueKey } = { labelKey: 'Name', valueKey: 'Id' }) => (
    options.map((option) => (
        (typeof option === 'string')
            ? { label: option, value: option }
            : { label: get(option, labelKey), value: get(option, valueKey).toString() }
    ))
);

export const getPeriodByFilter = (filter, format = 'YYYY-MM-DD') => {
    const filterPeriod = {
        startDate: '',
        endDate: formatDate(new Date(), format),
    };

    if (filter && Object.keys(filter).includes('startDate') && Object.keys(filter).includes('endDate')) {
        return {
            startDate: formatDate(filter.startDate, format),
            endDate: formatDate(filter.endDate, format),
        };
    }

    switch (filter) {
        case YTD:
            filterPeriod.startDate = `${getFirstDayOfYear()}`;
            break;
        case YEARS1:
            filterPeriod.startDate = subtractUnits(new Date(), 1, 'years');
            break;
        case YEARS3:
            filterPeriod.startDate = subtractUnits(new Date(), 3, 'years');
            break;
        case YEARS5:
            filterPeriod.startDate = subtractUnits(new Date(), 5, 'years');
            break;
        case SINCE_INCEPTION:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
            break;
        default:
            delete filterPeriod.startDate;
            delete filterPeriod.endDate;
    }

    return filterPeriod;
};

const multiply = (a, b) => +(a * b).toFixed(10);

export const sum = (data, prop) => {
    const total = data.reduce((accu, item) => accu + Number(item[prop]) * 10000000000, 0);

    return total / 10000000000;
};

export const roundItems = (arrDef, multiplier = 10000, sumItems = multiplier) => {
    if (arrDef.length === 0) return arrDef;
    const arr = arrDef.map((item) => multiply(item, multiplier));

    return roundSmallestError(arr, sumItems);
};

export const roundAllocations = (
    positions, multiplier = 10000, divide = 100, sumItems = multiplier,
) => {
    const arr = positions.map(({ Allocation }) => Allocation);
    const fixed = Math.log10(divide);

    return roundItems(arr, multiplier, sumItems).map((n, i) => ({
        id: positions[i].Security?.Id || positions[i].Id,
        value: Number((n / divide).toFixed(fixed)),
    }));
};

export const stripHtmlTags = (string) => string?.replace(/<[^>]*>?/gm, '');

export const isToday = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
};

export const isYesterday = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return date.getDate() === today.getDate() - 1
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
};

export const isTomorrow = (inputDate) => {
    const date = new Date(inputDate);
    const today = new Date();

    return date.getDate() === today.getDate() + 1
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
};

export const isEmpty = (data) => {
    if (data === undefined || data == null || data.length === 0) {
        return true;
    }

    if (['function', 'number', 'boolean'].includes(typeof (data)) || Object.prototype.toString.call(data) === '[object Date]') {
        return false;
    }

    if (typeof (data) === 'object') {
        if (Object.keys(data) && Object.keys(data).length > 0) {
            return false;
        }

        return true;
    }

    return false;
};

export const fieldHasError = (groupName, { Type }, value) => (Type === 'FreeText'
    && (!value || value === ''));

export const setOptions = (options) => options.map(({ Id, Text }) => ({
    value: Id,
    label: Text,
}));

export const getRandomString = (length = 2) => {
    const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const arr = Array(length).fill(0);

    return arr.reduce((acc) => acc + randomChars.charAt(Math.floor(Math.random() * randomChars.length)), '');
};

export const getRandomNumber = (length = 16) => {
    const timestamp = getTimestamp(new Date()).toString();
    const timestampSliced = timestamp.slice(Math.max(0, timestamp.length - length));
    const startString = Math.random().toString().substr(2, length - timestampSliced.length);

    return +`${startString}${timestampSliced}`;
};

export const getImageMime = (data) => {
    if (data) {
        if (data.charAt(0) === '/') {
            return 'image/jpeg';
        } if (data.charAt(0) === 'R') {
            return 'image/gif';
        } if (data.charAt(0) === 'i') {
            return 'image/png';
        }
        if (data.charAt(0) === 'P') {
            return 'image/svg+xml';
        }
    }

    return undefined;
};

export const insertIntoArray = (arr, index, newItem) => ([
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index),
]);

export function objectsDifference(object1, object2) {
    return transform(object1, (result, value, key) => {
        if (!isEqual(value, object2[key])) {
            result[key] = isObject(value)
                && isObject(object2[key])
                ? objectsDifference(value, object2[key]) : value;
        }
    });
}

export const roundArray = (
    arr, multiplier = 10000, divide = 100, sumItems = multiplier,
) => {
    const fixed = Math.log10(divide);

    return roundItems(arr, multiplier, sumItems).map((n) => (
        Number((n / divide).toFixed(fixed))));
};

export function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

export const convertToMilliseconds = (dateString) => {
    const date = new Date(dateString);

    return date.getTime();
};

export function formatFloatingNumber(num) {
    if (num % 1 === 0) {
        return num.toString();
    }

    return num.toFixed(2);
}
