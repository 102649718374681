import {useCallback, useEffect, useState} from 'react';
import {
    useBaseAgentInformation,
} from 'pages/ClientOverview/pages/Portfolios/components/AgentInformation/hooks/useBaseAgentInformation';
import {usePortfolioProcessNavigation} from 'hooks/helpers/usePortfolioProcessNavigation';
import {
    useCreatePortfolioActions,
    useCreatePortfolioSelectors,
    useCreatePortfolioState,
} from 'datasource/CreatePortfolio';
import SM from 'services/ServiceManager';
import {adaptModels, adaptStrategies} from 'pages/ClientOverview/pages/Portfolios/adapters/adaptModelPortfolio';
import {useTranslation} from 'react-i18next';
import {notification} from 'ui-library';

export const useAgentInformation = ({
    dfsClientId, saveFeeData, isPk,
}) => {
    const [pageErrors, setPageErrors] = useState([]);
    const [isPreloadInProgress, setIsPreloadInProgress] = useState(true);
    const {i18n: {language}} = useTranslation();
    const {
        selectedProductSelector,
        currentInvestmentApplicationSelector,
        is3bProductGroupSelector,
    } = useCreatePortfolioSelectors();
    const {
        saveInvestmentApplicationDataAction,
    } = useCreatePortfolioActions();
    const {
        currentInvestmentApplicationId,
    } = useCreatePortfolioState();

    useEffect(() => {
        (async () => {
            if (isPk) {
                try {
                    const params = {language};

                    const responseMP = await SM.portfolioManagement(
                        'getModelPortfolios',
                        [
                            selectedProductSelector?.id,
                            params,
                        ],
                    );

                    const model = adaptModels(responseMP.data)[0];
                    const riskCategory = adaptStrategies(responseMP.data)[0];

                    const payload = {
                        strategyId: riskCategory?.Id,
                        investmentHorizon: 4,
                        modelPortfolioId: model?.Id,
                        withdrawalOption: false,
                    };

                    const additionalData = {
                        riskCategory,
                        modelPortfolio: model,
                    };

                    await saveInvestmentApplicationDataAction({
                        payload,
                        method: 'saveStrategyData',
                        additionalData,
                    });

                    if (currentInvestmentApplicationId) {
                        await SM.customInvestmentService('saveProductData', [{investmentApplicationId: currentInvestmentApplicationId, initialInvestment: currentInvestmentApplicationSelector?.investmentDescription?.productConfiguration?.initialInvestment}]);
                    }
                } catch (error) {
                    console.error(error);
                    notification.open({content: t('general.somethingWentWrong'), type: 'error'});
                } finally {
                    setIsPreloadInProgress(false);
                }
            } else {
                setIsPreloadInProgress(false);
            }
        })();
    }, [language, isPk, currentInvestmentApplicationId]);

    const newPortfolio = {
        product: selectedProductSelector,
        applicationData: currentInvestmentApplicationSelector,
        modelPortfolio: currentInvestmentApplicationSelector.modelPortfolio,
    };
    const {
        t,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        isValid,
        validation,
        setContinuesValidation,
        changeVSTNumber,
        vstNumber,
        isLoading,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    } = useBaseAgentInformation({
        dfsClientId,
        newPortfolio,
    });

    const onContinue = useCallback(async () => {
        setContinuesValidation(true);
        if (isValid) {
            try {
                const payload = {
                    issueCommission: issueCommissionSelected || '0',
                    pbNumber: String(pbSelected),
                    vstNumber,
                    agencyId: selectedGeneralAgency,
                    agentId: selectedAgent,
                };

                let feeOverview;

                if (saveFeeData && !isPk) {
                    feeOverview = await saveFeeData();
                }
                const result = await saveInvestmentApplicationDataAction({
                    payload,
                    method: 'saveAgentData',
                    additionalData: {
                        agentInformation: {
                            pbSelected,
                            issueCommissionSelected,
                            vstNumber,
                            selectedGeneralAgency,
                            selectedAgent,
                        },
                        feeOverview,
                    },
                });

                if (!result) return;
                setStep({
                    stepKey: is3bProductGroupSelector ? 'ShoppingCard' : 'Non3BShoppingCard',
                });
            } catch (err) {
                setPageErrors(err.message);
            }
        }
    }, [
        dfsClientId, pbSelected, vstNumber,
        issueCommissionSelected, isValid, selectedGeneralAgency, selectedAgent, saveFeeData, isPk,
    ]);

    const {setStep} = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        navigationDisabled: isLoading,
        pageErrors,
    });

    return {
        t,
        isPreloadInProgress,
        validation,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbChange,
        advisorData,
        changeVSTNumber,
        vstNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    };
};
