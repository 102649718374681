/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ICON_EDIT_SLIM } from 'ui-library/components/Icon';
import {Icon} from 'ui-library';
import { OtherAssetsFormData } from 'datasource/useOtherAssets/useOtherAssets.types';
import IconWithTooltip from 'components/common/IconWithTooltip';
import { roundTwoDecimalDigit } from 'utils/rounding';
import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';

export const useGetAssetsTableColumns = () => {
    const getColumns = ({
        onEditAssetRow = () => {},
        instituteOptions,
        strategyOptions = [],
    }: {
        onEditAssetRow?: (record?: OtherAssetsFormData) => void,
        categoryOptions?: any[],
        instituteOptions?: any[],
        strategyOptions?: any[]
    }) => [
        {
            key: 'instituteListItemId',
            title: 'Institute',
            sortable: true,
            minWidth: 150,
            maxWidth: 150,
            render: (_, record: OtherAssetsFormData) => (
                <>
                    {!instituteOptions ? <strong>Total</strong> : (
                        <>
                            {instituteOptions?.find(ins => ins.value === record?.instituteListItemId)?.label}
                            {record?.description && (
                                <IconWithTooltip description={record?.description} />
                            )}
                        </>
                    )}
                </>
            ),
        },
        {
            key: 'strategyListItemId',
            title: 'Strategy',
            // className: 'text-right',
            // hidden: true,
            minWidth: 170,
            maxWidth: 170,
            render: (_, record: OtherAssetsFormData) => (
                <>
                    {strategyOptions?.find(strategy => strategy.value === record?.strategyListItemId)?.label}
                </>
            ),
        },
        {
            key: 'value',
            title: 'Value',
            // className: 'text-right',
            minWidth: 170,
            maxWidth: 170,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`CHF ${applyThousandSeparator((roundTwoDecimalDigit(record?.value) || 0).toString(),'’')}`}
                </div>
            ),
        },
        {
            key: 'stocks',
            title: 'Stocks',
            // className: 'text-right',
            minWidth: 110,
            maxWidth: 110,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.stocks) || 0}%`}
                </div>
            ),
        },
        {
            key: 'liquidity',
            title: 'Cash/Liquid.',
            // className: 'text-right',
            minWidth: 130,
            maxWidth: 130,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.liquidity) || 0}%`}
                </div>
            ),
        },
        {
            key: 'bonds',
            title: 'Bonds',
            // className: 'text-right',
            // hidden: true,
            minWidth: 110,
            maxWidth: 110,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.bonds) || 0}%`}
                </div>
            ),
        },
        {
            key: 'realEstate',
            title: 'Real Estate.',
            // className: 'text-right',
            minWidth: 130,
            maxWidth: 130,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.realEstate) || 0}%`}
                </div>
            ),
        },
        {
            key: 'rawMaterial',
            title: 'Raw Materials',
            // className: 'text-right',
            // hidden: true,
            minWidth: 130,
            maxWidth: 130,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.rawMaterial) || 0}%`}
                </div>
            ),
        },
        {
            key: 'tiedAssets',
            title: 'Tied Assets',
            // className: 'text-right',
            // hidden: true,
            minWidth: 130,
            maxWidth: 130,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.tiedAssets) || 0}%`}
                </div>
            ),
        },
        {
            key: 'other',
            title: 'Others',
            // className: 'text-right',
            // hidden: true,
            minWidth: 110,
            maxWidth: 110,
            render: (_, record: OtherAssetsFormData) => (
                <div className="text-right">
                    {`${roundTwoDecimalDigit(record?.allocations?.other) || 0}%`}
                </div>
            ),
        },
        {
            key: 'Icon',
            title: '',
            titleType: 'columns',
            minWidth: 25,
            render: (_, record) => (
                <button className="edit-asset-row-button" type="button" onClick={() => onEditAssetRow(record)} aria-label="button">
                    {/* @ts-ignore */}
                    <Icon type={ICON_EDIT_SLIM} size={20} />
                </button>
            ),
            className: 'text-right',
            dragDisabled: true,
        },
    ];

    return {getColumns};
};
