/* eslint-disable @typescript-eslint/ban-ts-comment */
import { adaptAnswers } from 'adaptors/adaptAnswers';
import Preloader from 'components/Preloader';

import { usePortfolioProcessNavigation } from 'hooks/helpers/usePortfolioProcessNavigation';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Infobox, Loader } from 'ui-library';
import { getClientId } from 'utils';

import { InvestorProfileWithProvider } from './InvestorProfileWithProvider';
import './InvestorProfile.css';

export function InvestorProfilesContainer(props) {
    const { t, i18n: { language } } = useTranslation();
    const { dataSource } = props;

    const {
        isPk, isJointAccount,
        isUsersLoading, saveAdditionalData,
        productDescription,
        clientAge,
        childClientName,
        targetClientName,
        targetDFSClientId,
        saveAdvisoryDataAction,
        advisoryProcessInProgressSelector,
    } = dataSource();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setSending] = useState(false);
    const [profiles, setProfiles] = useState({});

    // Handlers/Callbacks

    const onQuestionnaireUpdate = useCallback((key, value) => {
        setProfiles({
            ...profiles,
            [key]: value,
        });
    }, [profiles]);

    // Handlers/Callbacks
    const onContinue = useCallback(async () => {
        try {
            setIsSubmitted(true);
            const allQuestionsFilled = Object.keys(profiles).map(key => profiles?.[key]?.allQuestionsFilled);

            if (!allQuestionsFilled?.some(a => a === false)) {
                const clientRiskToAccept = Math.max(...Object.keys(profiles).map(dfsId => getClientId(dfsId)));

                setSending(true);
                await Promise.all(Object.keys(profiles).map(async (key) => {
                    const {
                        clientId: mClientId,
                        data,
                        fullData,
                        formData,
                        groupId,
                        isDataChanged,
                        postQuestionnaire,
                        calculateRisk,
                        setChosenRisk,
                    } = profiles?.[key];

                    const answers = adaptAnswers({ data, values: formData, groupId });
                    const isQChanged = isDataChanged();

                    let response;

                    if (isQChanged) {
                        await postQuestionnaire({ answers });
                        response = await calculateRisk();
                        await setChosenRisk({ id: response?.RiskCategory?.Id });
                    } else {
                        response = fullData;
                    }

                    if (saveAdditionalData) {
                        await saveAdditionalData({ clientId: mClientId, clientRiskToAccept, response });
                    }

                    if (advisoryProcessInProgressSelector && mClientId && response?.Score && response?.RiskCategory?.Id) {
                        await saveAdvisoryDataAction({
                            key: 'investor-profile',
                            payload: {
                                ContactId: mClientId,
                                Score: response?.Score,
                                CalculatedRiskCategoryId: response?.RiskCategory?.Id,
                            },
                        });
                    }
                }));
                setSending(false);
                setIsSubmitted(false);

                stepComplete();
            }
        } catch (err) {
            console.error(err);
        }
    }, [profiles, isPk, language, saveAdditionalData]);

    const isAnyInvestorProfileLoading = useMemo(() => Object.keys(profiles).map(key => profiles?.[key]?.isLoading)?.some((a) => (a === true)), [profiles]);

    const { stepComplete } = usePortfolioProcessNavigation({
        saveOnContinue: onContinue,
        enableAutoStepComplete: false,
        continueInProgress: isAnyInvestorProfileLoading || isSending,
        pageErrors: useMemo(() => Object.keys(profiles).find(key => profiles?.[key]?.pageError), [profiles]),
    });

    if (isUsersLoading) {
        return <Loader />;
    }

    return (
        <Preloader isLoading={!targetDFSClientId}>
            <InvestorProfileWithProvider
                clientName={targetClientName}
                clientAge={clientAge}
                title={productDescription}
                sessionStorageKey={`create-portfolio/investor-profile/${targetDFSClientId}/data`}
                dfsClientId={targetDFSClientId}
                onQuestionnaireUpdate={onQuestionnaireUpdate}
                isSubmitted={isSubmitted}
                isSending={isSending}
            />
            {isJointAccount && (
                <Infobox className="ja-message">
                    {t('portfolio.createNewPortfolio.investorProfile.jaInfoMessage', { clientFullName: childClientName, clientOwnerFullName: targetClientName })}
                </Infobox>
            )}
        </Preloader>
    );
}
