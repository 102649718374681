import { applyThousandSeparator } from 'ui-library/components/NumberInput/utils';
import { defaultPieChartOptions } from './constants';

export const getSum = (data) => (data.length > 0
    ? data.map((item) => item.value).reduce((sum, i) => sum + i)
    : 0);

const sortChartsArray = (data) => [...data].sort((a, b) => b.value - a.value);

export const buildDataArray = (data) => data.map(({
    name, value, percent, ...rest
}) => ({
    name, y: value, percent, ...rest,
}));

export const buildAdditionalCss = (data, index) => data.reduce(
    (acc, { color }, idx) => ({ ...acc, [`--pie-chart-colors-${index || idx}`]: color }), {},
);

export const buildSingleDataArrays = (data, sum) => data.map(({
    name, value, percent, color,
}) => [{
    name, y: value, percent, color,
}, { name: '', y: sum - value, color }]);

export const getPieChartOptions = (data, sum, singleAllocation, percentFormat, chartSize) => {
    const subtitleText = `${singleAllocation
        ? `${percentFormat(data[0].percent || (data[0].y / sum) * 100)}`
        : ''}`;

    function formatter() {
        console.log({point: this.point})
        return `<div style="text-align: center;">
                    <div style="color: #142966; font-size: 12px; font-weight:600"><strong>CHF ${this.point.y ? applyThousandSeparator(`${this.point.y}`, "’") : '0'}</strong></div>
                    <div style="font-weight:400">${percentFormat(this.point.percentage)}%</div>
                </div>`;
    }

    const positioner = (labelWidth, labelHeight, point) => (
        { x: point.plotX - 0.5 * labelWidth, y: point.plotY - labelHeight - 7 }
    );

    const chartSizeOptions = chartSize ? { height: chartSize, width: chartSize } : {};

    return {
        ...defaultPieChartOptions,
        chart: {...defaultPieChartOptions.chart, ...chartSizeOptions},
        subtitle: { ...defaultPieChartOptions.subtitle, text: subtitleText },
        tooltip: { ...defaultPieChartOptions.tooltip, formatter },
        series: [{ ...defaultPieChartOptions.series[0], data }],
    };
};

export const buildLegend = (data, sum, singleAllocation, percentFormat) => {
    const getPercent = (value, percent) => percentFormat(percent
            || (Math.round((value / sum) * 10000) / 100));

    return data.filter(({ name }) => name)
        .map(({ name, y, percent }) => ({
            label: name,
            percent: singleAllocation ? undefined : getPercent(y, percent),
            value: y,
        }));
};

export const adaptDataPieChart = (data, maxItems) => {
    const sortArray = (data);

    if (data.length <= maxItems) {
        return sortArray;
    }

    const other = sortArray.slice(maxItems - 1);

    const otherValue = other.reduce((acc, item) => {
        if (Object.prototype.hasOwnProperty.call(item, 'percent')) {
            return {
                ...acc,
                value: acc.value + item.value,
                percent: acc?.percent
                    ? +parseFloat(acc?.percent + item.percent).toFixed(2) : item.percent,
            };
        }
        acc.value += item.value;

        return acc;
    }, { value: 0 });

    return [...sortArray.slice(0, maxItems - 1), { name: 'Other', ...otherValue }];
};
