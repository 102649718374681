import React from 'react';
import FeesOverviewBase from 'pages/ClientOverview/pages/Portfolios/components/FeesOverview/fees-overview-base';

import {useParams} from 'react-router-dom';
import {useThreeBProductType} from 'hooks/isThreeBProduct';
import AgentInformationBase from 'pages/ClientOverview/pages/Portfolios/components/AgentInformation';
import {useCreatePortfolioSelectors} from 'datasource/CreatePortfolio';
import {useAgentInformation} from '../AgentInformation/hooks/useAgentInformation';
import {useCustodyFee} from './hooks/useCusotdyFee';

import '../PersonalDetails/PersonalDetails.css';
import Preloader from 'components/Preloader';

function FeesOverview() {
    const {dfsClientId} = useParams();

    const {
        selectedProductSelector,
        currentInvestmentApplicationSelector,
    } = useCreatePortfolioSelectors();
    const zurichEmployee = currentInvestmentApplicationSelector?.investmentDescription?.personalDetails?.zurichEmployee;
    const {
        isZivAzp, isZic, isAzp, isPk,
    } = useThreeBProductType({product: selectedProductSelector});
    const {
        feeData, onSave: saveFeeData, defaultCustodyFee, defaultAssetFee, saveFeeLocally, defaultEntryFee,
    } = useCustodyFee({
        dfsClientId, isZivAzp, isZic, isAzp,
    });

    const {
        t,
        isPreloadInProgress,
        formatPhoneNumber,
        productDesc,
        issueCommissionSelected,
        issueCommissionChange,
        issueCommissionOptions,
        pbSelected,
        pbSelectOptions,
        fullPbNumber,
        pbChange,
        isPBTextFieldAvailable,
        advisorData,
        newPBValue,
        validation,
        onChangeNewPBNumber,
        changeVSTNumber,
        vstNumber,
        generalAgencies,
        selectedGeneralAgency,
        setSelectedGeneralAgency,
        agentsList,
        selectedAgent,
        setSelectedAgent,
        handleSearchAgents,
        searchAgentsInProgress,
    } = useAgentInformation({
        dfsClientId, saveFeeData, isPk,
    });

    return (
        <>
            {!isPk && (
                <FeesOverviewBase
                    t={t}
                    productDesc={productDesc}
                    defaultCustodyFee={defaultCustodyFee}
                    feeData={feeData}
                    defaultAssetFee={defaultAssetFee}
                    isZivAzp={isZivAzp}
                    isZic={isZic}
                    saveFeeLocally={saveFeeLocally}
                    defaultEntryFee={defaultEntryFee}
                />
            )}
            <Preloader isLoading={isPreloadInProgress}>
                <AgentInformationBase
                    {...{
                        t,
                        validation,
                        formatPhoneNumber,
                        issueCommissionSelected,
                        issueCommissionChange,
                        issueCommissionOptions,
                        pbSelected,
                        pbSelectOptions,
                        fullPbNumber,
                        pbChange,
                        isPBTextFieldAvailable,
                        advisorData,
                        newPBValue,
                        onChangeNewPBNumber,
                        changeVSTNumber,
                        vstNumber,
                        generalAgencies,
                        selectedGeneralAgency,
                        setSelectedGeneralAgency,
                        agentsList,
                        selectedAgent,
                        setSelectedAgent,
                        handleSearchAgents,
                        searchAgentsInProgress,
                        isZic,
                        isPk,
                        zurichEmployee,
                    }}
                />
            </Preloader>
        </>
    );
}

export default FeesOverview;
