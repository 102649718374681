import React from 'react';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import QuestionnaireGroup from 'components/QuestionnaireGroup';

import './InvestorProfileBase.css';

export function InvestorProfileBase({
    errors,
    isLoading,
    investorProfileGroup,
    title,
    values,
    onChange,
    data,
    t,
}) {
    return (
        <div className="new-portfolio">
            <Preloader
                isLoading={isLoading}
            >
                <div className="new-portfolio-content">
                    <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                        <QuestionnaireGroup
                            data={investorProfileGroup}
                            title={title}
                            value={values || {}}
                            errors={errors}
                            onChange={onChange}
                        />
                    </EmptyContent>
                </div>
            </Preloader>
        </div>
    );
}
