import {useCallback, useEffect, useMemo, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { APPLICATION_CONTAINER_ID } from 'constants/constants';
import {usePortfolioProcessNavigation} from '../hooks/helpers/usePortfolioProcessNavigation';
import {useCreatePortfolioActions, useCreatePortfolioSelectors} from './CreatePortfolio';
import {useManagerProfile} from '../prodivers/managerProfile';

export const useAdvisoryProcess = () => {
    const {hasAccessPolicy} = useManagerProfile();
    const { dfsClientId } = useParams();
    const navigate = useNavigate();

    const {t} = useTranslation();
    const items = useMemo(() => [
        {
            id: 2,
            name: t('advisoryDocumentInit.step.no'),
        },
        {
            id: 1,
            name: t('advisoryDocumentInit.step.yes'),
            disabled: !hasAccessPolicy('EnableAdvisoryProcess'),
        },
    ], [t]);

    const [initiateAdvisoryProcess, setInitiateAdvisoryProcess] = useState<number | null>(null);
    const {contactGroupIdSelector, isAdvisoryProcessSelector, isAdvisoryDataLoading} = useCreatePortfolioSelectors();
    const {
        setShouldInitiateAdvisoryProcessAction,
        setUploadedAdvisoryDocumentAction,
        startAdvisoryProcessAction,
        advisoryProcessInProgressAction,
    } = useCreatePortfolioActions();

    const handleOnContinue = useCallback(() => {
        if (initiateAdvisoryProcess === 2) {
            stepComplete();
            unlockNavigationAction();
        } else {
            setShouldInitiateAdvisoryProcessAction(1);
        }
    }, [initiateAdvisoryProcess]);

    const handleOnBack = useCallback(() => {
        if (isAdvisoryProcessSelector) {
            setShouldInitiateAdvisoryProcessAction(0);
        } else {
            navigate(`/client/${dfsClientId}`);
        }
    }, [isAdvisoryProcessSelector]);

    const {stepComplete, unlockNavigationAction} = usePortfolioProcessNavigation({
        preventNavigationLock: true,
        continueDisabled: !initiateAdvisoryProcess || !!isAdvisoryProcessSelector,
        saveOnContinue: handleOnContinue,
        saveOnBack: handleOnBack,
        enableAutoStepComplete: false,
    });

    const setShouldInitiateAdvisoryProcess = (val) => {
        setInitiateAdvisoryProcess(val);
        sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
    };

    useEffect(() => {
        advisoryProcessInProgressAction(false);
        setUploadedAdvisoryDocumentAction(null);
        global.sessionStorage.removeItem(APPLICATION_CONTAINER_ID);
    }, []);

    return {
        items,
        initiateAdvisoryProcess,
        shouldInitiateAdvisoryProcess: isAdvisoryProcessSelector,
        setShouldInitiateAdvisoryProcess,
        contactGroupIdSelector,
        setUploadedAdvisoryDocumentAction,
        stepComplete,
        startAdvisoryProcessAction,
        isAdvisoryDataLoading,
    };
};
