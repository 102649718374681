import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import { downloadDmsDocument } from 'utils/downloadDmsDocument';
import { notification } from 'ui-library';

export type DocumentPayload = {
    contactGroupId: number,
    date: Date,
    number: string,
    investmentAmount: number,
    system: number,
    document: {
        filename: string,
        file: string
    }
}

export type DocumentType = {
    advisoryDate:Date,
    advisoryNumber: number,
    advisoryDocumentId: number,
    advisorySystem: number,
    createDate: Date
    documentId: number,
    fileName: string
}

type UseAdvisoryDocumentBaseProps = {
    contactGroupId?: number | null
}

type UseAdvisoryDocumentBase = {
    isLoading: boolean;
    saveInProgress: boolean;
    document: DocumentType | null;
    onContinue: (payload: DocumentPayload) => Promise<DocumentType>
    getDocument: (documentId: number) => Promise<void>
    downloadDocument: (docId: number) => Promise<void>
    downloadDocumentInProgress: boolean;
    setDocument: (val: DocumentType) => void;
}

export const useAdvisoryDocumentBase = (props?: UseAdvisoryDocumentBaseProps): UseAdvisoryDocumentBase => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
    const [document, setDocument] = useState<DocumentType | null>(null);
    const [downloadDocumentInProgress, setDownloadDocumentInProgress] = useState(false);

    const getDocument = useCallback(async (documentId: number) => {
        try {
            setIsLoading(true);
            const result: AxiosResponse<DocumentType> = await ServiceManager.customDocumentsService('getAdvisoryDocument', [{ advisoryDocumentId: documentId }]);

            setDocument(result.data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const onContinue = useCallback(async (payload: DocumentPayload) => {
        try {
            setSaveInProgress(true);

            const result: AxiosResponse<DocumentType> = await ServiceManager.customDocumentsService('uploadAdvisoryDocument', [{ payload }]);

            return result.data;
        } catch (err: any) {
            notification.open({ content: `${t('advisoryDocument.somethingWentWrong')} ${err.message}`, type: 'error' });
            throw err;
        } finally {
            setSaveInProgress(false);
        }
    }, []);

    const downloadDocument = useCallback(async (documentId: number) => {
        try {
            setDownloadDocumentInProgress(true);
            const { data: documentData } = await ServiceManager.documents('getDocumentById', [documentId]);

            const { newBlob } = downloadDmsDocument(documentData, { saveFile: true, name: null });

            const url = URL.createObjectURL(newBlob);

            global.open(url, '_blank');
        } catch (err: any) {
            notification.open({ content: `${t('advisoryDocument.somethingWentWrong')} ${err.message}`, type: 'error' });
        } finally {
            setDownloadDocumentInProgress(false);
        }
    }, [t]);

    return {
        isLoading, saveInProgress, document, setDocument, onContinue, getDocument, downloadDocument, downloadDocumentInProgress,
    };
};
