import moment from 'moment';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Modal} from 'ui-library';
import { useNavigate, useParams } from 'react-router-dom';
import { CREATE_APP_CLIENT_ID } from 'pages/ClientOverview/constants';
import {getRestrictedClientMessage} from '../../../pages/ClientOverview/utils/utils';
import {genarateDFSClientId, getClientId} from '../../../utils';
import {useContact} from '../../../domain/ClientService';

export const useActions = () => {
    const { getContact } = useContact();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { dfsClientId } = useParams();

    const continueApplication = useCallback(async ({contactId, hideLoaders, investmentApplicationId}) => {
        const contactDataForm = await getContact({contactId});
        const userClientAge = moment(moment().format('YYYY-MM-DD')).diff(moment(contactDataForm?.details?.personalInformation?.dateOfBirth), 'years', true);
        const countryOfResidence = contactDataForm?.details?.communicationMethods?.primaryAddressCountry;
        const taxData = contactDataForm?.details?.taxSituation;
        const nationality = contactDataForm?.details?.personalInformation?.identification?.[0]?.countryOfNationality;

        const clientFitFailMessage = getRestrictedClientMessage({
            data: {
                userClientAge,
                countryOfResidence,
                taxData,
                nationality,
            },
            t,
        });

        if (clientFitFailMessage) {
            hideLoaders();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Modal.info({
                title: t('portfolio.continue.modal.title.forbidden'),
                content: clientFitFailMessage,
                okText: t('clientDashboard.confirm'),
            });
        } else {
            sessionStorage.setItem(CREATE_APP_CLIENT_ID, String(getClientId(dfsClientId)));
            navigate(`/client/${genarateDFSClientId(contactId)}/portfolios/new-portfolio`, {
                state: {
                    investmentApplicationId,
                },
            });
        }
    }, [dfsClientId]);

    return {
        continueApplication,
    };
};
