import { adaptAnswers } from 'adaptors/adaptAnswers';
import { useSetChosenRisk } from 'hooks/useSetChosenRisk';
import useFormatting from 'locale/useFormatting';
import isEqual from 'lodash/isEqual';
import { useClientProfile } from 'prodivers/clientProfile';
import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getClientId } from 'utils';
import { formDataSelector, questionnaireSelector } from '../selectors';
import { useSelector } from '../useSelector';

export const useInvestorProfile = ({
    dfsClientId, clientAge, advisoryDataSelector = null,
}) => {
    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate } = useFormatting();

    const clientId = getClientId(dfsClientId);
    const [isEditMode, setEditMode] = useState(true);

    const annualGrossIncome = advisoryDataSelector?.metadata?.incomeLiquidity?.grossAnnualIncome || undefined;
    const annualSavings = advisoryDataSelector?.metadata?.incomeLiquidity?.annualSavedAmount || undefined;
    const additionalAssets = advisoryDataSelector?.metadata?.incomeLiquidity?.totalLiquidity || undefined;
    const reservedLiquidityOf3MonthsSalary = useMemo(() => {
        const threeMonthsSalary = (annualGrossIncome / 4);

        if (additionalAssets && threeMonthsSalary) {
            return (additionalAssets || 0) >= threeMonthsSalary ? 'true' : 'false';
        }

        return undefined;
    }, [advisoryDataSelector?.metadata?.incomeLiquidity]);

    // Client Provider
    const { ...information } = useClientProfile();
    const {
        data, fullData, isLoading, getQuestionnaire, validateQuestionnaire,
        isCompletedAndNotExpired,
        isLoadingPost, postQuestionnaire,
        calculateRisk, error: pageError,
    } = useSelector(questionnaireSelector);

    const { setChosenRisk, isSending: isChosenRiskSending, error: chosenRiskError } = useSetChosenRisk({ clientId, withAccept: true });
    const investorProfileGroup = useMemo(() => data?.Groups[0], [data?.Groups]);
    const groupId = useMemo(() => investorProfileGroup?.Id, [investorProfileGroup?.Id]);
    const ageQuestion = useMemo(() => investorProfileGroup?.Steps?.[0].Questions[0], [investorProfileGroup?.Steps?.[0].Questions[0]]);
    const grossIncomeQuestion = useMemo(() => investorProfileGroup?.Steps?.[0].Questions[1], [investorProfileGroup?.Steps?.[0].Questions[1]]);
    const annualSavingsQuestion = useMemo(() => investorProfileGroup?.Steps?.[0].Questions[2], [investorProfileGroup?.Steps?.[0].Questions[2]]);
    const additionalAssetsQuestion = useMemo(() => investorProfileGroup?.Steps?.[0].Questions[3], [investorProfileGroup?.Steps?.[0].Questions[3]]);
    const reservedLiquidityOf3MonthsSalaryQuestion = useMemo(() => investorProfileGroup?.Steps?.[0].Questions[4], [investorProfileGroup?.Steps?.[0].Questions[4]]);
    const isQuestionnaireCompletedAndNotExpired = useMemo(() => isCompletedAndNotExpired(fullData),
        [fullData?.ExpiryDate, fullData?.CreateDate, fullData?.IsComplete]);
    const expirationDate = useMemo(() => getFormattedDate(fullData?.ExpiryDate), [fullData?.ExpiryDate]);

    const {
        data: formData, initializeData, saveData,
    } = useSelector(formDataSelector);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(
        () => validateQuestionnaire({ groupId, answers: formData }),
        [formData, groupId, validateQuestionnaire],
    );

    useEffect(() => {
        if (isQuestionnaireCompletedAndNotExpired) setEditMode(false);
    }, [isQuestionnaireCompletedAndNotExpired]);

    useEffect(() => {
        if (clientId) {
            getQuestionnaire();
        }
    }, [language, clientId]);

    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({ Id }) => Id);

            initializeData({ groupIds });
        }
    }, [data, formData, initializeData]);

    useEffect(() => {
        if (data && Object.keys(data).length && groupId) {
            const questions = [];

            if (!ageQuestion?.Value?.Ids?.length && clientAge) {
                let answerIndex = 0;

                if (parseInt(clientAge, 10) < 35) {
                    answerIndex = 0;
                } else if (parseInt(clientAge, 10) < 51) {
                    answerIndex = 1;
                } else if (parseInt(clientAge, 10) < 61) {
                    answerIndex = 2;
                } else {
                    answerIndex = 3;
                }
                questions.push({ questionId: ageQuestion?.Id, value: ageQuestion?.Answers[answerIndex].Id });
            }

            if (!grossIncomeQuestion?.Value?.Ids?.length && annualGrossIncome) {
                let answerIndex = 0;

                if (parseInt(annualGrossIncome, 10) < 75000) {
                    answerIndex = 0;
                } else if (parseInt(annualGrossIncome, 10) <= 150000) {
                    answerIndex = 1;
                } else if (parseInt(annualGrossIncome, 10) <= 300000) {
                    answerIndex = 2;
                } else if (parseInt(annualGrossIncome, 10) <= 500000) {
                    answerIndex = 3;
                } else {
                    answerIndex = 4;
                }
                questions.push({ questionId: grossIncomeQuestion?.Id, value: grossIncomeQuestion?.Answers[answerIndex].Id });
            }

            if (!annualSavingsQuestion?.Value?.Ids?.length && annualSavings) {
                let answerIndex = 0;

                if (parseInt(annualSavings, 10) < 10000) {
                    answerIndex = 0;
                } else {
                    answerIndex = 1;
                }
                questions.push({ questionId: annualSavingsQuestion?.Id, value: annualSavingsQuestion?.Answers[answerIndex].Id });
            }

            if (!additionalAssetsQuestion?.Value?.Ids?.length && additionalAssets) {
                let answerIndex = 0;

                if (parseInt(additionalAssets, 10) < 150000) {
                    answerIndex = 0;
                } else if (parseInt(additionalAssets, 10) <= 350000) {
                    answerIndex = 1;
                } else if (parseInt(additionalAssets, 10) <= 650000) {
                    answerIndex = 2;
                } else if (parseInt(additionalAssets, 10) <= 1000000) {
                    answerIndex = 3;
                } else if (parseInt(additionalAssets, 10) <= 2000000) {
                    answerIndex = 4;
                } else {
                    answerIndex = 5;
                }
                questions.push({ questionId: additionalAssetsQuestion?.Id, value: additionalAssetsQuestion?.Answers[answerIndex].Id });
            }

            if (!reservedLiquidityOf3MonthsSalaryQuestion?.Value?.Ids?.length && reservedLiquidityOf3MonthsSalary) {
                let answerIndex = 0;

                if (reservedLiquidityOf3MonthsSalary === 'true') {
                    answerIndex = 0;
                } else {
                    answerIndex = 1;
                }
                questions.push({ questionId: reservedLiquidityOf3MonthsSalaryQuestion?.Id, value: reservedLiquidityOf3MonthsSalaryQuestion?.Answers[answerIndex].Id });
            }

            if (questions?.length) {
                saveData({ groupId, questions });
            }
        }
    }, [
        clientAge,
        annualGrossIncome,
        annualSavings,
        additionalAssetsQuestion,
        reservedLiquidityOf3MonthsSalary,
        data,
        groupId,
        ageQuestion?.Value?.Ids,
        grossIncomeQuestion?.Value?.Ids,
        annualSavingsQuestion?.Value?.Ids,
        additionalAssetsQuestion?.Value?.Ids,
        reservedLiquidityOf3MonthsSalaryQuestion?.Value?.Ids,
    ]);

    const editInvestorProfile = useCallback(() => {
        setEditMode(true);
    }, []);

    const onChange = useCallback(({ questionId, value }) => {
        saveData({ groupId, questions: [{ questionId, value }] });
    }, [groupId, saveData]);

    const isDataChanged = useCallback(() => {
        const answers = adaptAnswers({ data, values: formData, groupId });

        return !isEqual(answers, data);
    }, [investorProfileGroup, formData]);

    return {
        isEditMode,
        isDataChanged,
        isQuestionnaireCompletedAndNotExpired,
        expirationDate,
        editInvestorProfile,
        isLoading: information?.isLoading || isLoading || isLoadingPost || isChosenRiskSending,
        clientId,
        postQuestionnaire,
        calculateRisk,
        setChosenRisk,
        allQuestionsFilled,
        errors,
        investorProfileGroup,
        formData,
        groupId,
        data,
        onChange,
        pageError: pageError || chosenRiskError,
        t,
        score: fullData?.Score,
        fullData,
    };
};
