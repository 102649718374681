import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Title, Infobox } from 'ui-library';
import PieChartWithLegend from 'components/PieChartWIthLegend/PieChartWithLegend';
import { RiskComparisonCompProps } from './RiskComparisonComp.types';
import './RiskComparisonComp.css';

const RiskComparisonComp:FC<RiskComparisonCompProps> = ({ title, subTitle, chartData }) => {
    const { t } = useTranslation();

    return (
        <section className="strategy-risk-comparison-comp">
            <Title type={2}>
                {title}
            </Title>
            <div className="strategy-risk-comparison-comp--subtitle">{subTitle}</div>
            <Title type={4} className="strategy-risk-comparison-comp--description">
                {t('RiskBandwidthComparison.Description.BeforeOptimization')}
            </Title>

            {chartData?.length ? <PieChartWithLegend chartData={chartData} /> : (
                <Infobox className="mt-30 w-full">
                    {t('RiskBandwidthComparison.noChartMessage')}
                </Infobox>
            )}
        </section>
    );
};

export default React.memo(RiskComparisonComp);
