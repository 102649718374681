/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
import React, {FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {
    Button, Icon, Modal, NumberInput, Select, TextInput, Title,
} from 'ui-library';
import {ICON_TYPE_BIN, ICON_TYPE_CLOSE_SLIM} from 'ui-library/components/Icon';
import EmptyComp from 'components/common/EmptyComp';
import AddButton from 'components/common/AddButton';
import {OtherAssetsProps} from './OtherAssets.types';
import AssetsDataTable from './AssetsDataTable';
import './OtherAssets.css';

const OtherAssets: FC<OtherAssetsProps> = ({datasource = () => {}}) => {
    const {
        categoryOptions,
        instituteOptions,
        strategyOptions,
        defaultValues,
        error,
        showAddAssetModal,
        control,
        errors,
        assetsTableData,
        totalAssetsData,
        assetDataCategories,
        totalAssetPercentage,
        isTableLoading,
        handleAddAsset,
        deleteAsset,
        handleStrategyAllocations,
        handleEditAssetRow,
        toggleAddAssetModal,
    } = datasource();

    const {t} = useTranslation();

    const renderFooter = useCallback((showDeleteBtn: boolean = false) => (
        <div className="other-assets-modal-footer">
            {showDeleteBtn && (
                <Button
                    type="danger-outline"
                    loading={false}
                    onClick={deleteAsset}
                >
                    <Icon
                        type={ICON_TYPE_BIN}
                        size={20}
                        ariaLabel={undefined}
                        color={undefined}
                        component={undefined}
                        className={undefined}
                    />
                    {t('OtherAssetStep.modal.deleteEntry')}
                    {/* Delete entry */}
                </Button>
            )}
            <Button onClick={() => toggleAddAssetModal(false)} className="ml-auto">
                {t('OtherAssetStep.modal.cancel')}
                {/* Cancel */}
            </Button>
            <Button
                type="primary"
                loading={false}
                onClick={handleAddAsset}
            >
                {t('OtherAssetStep.modal.saveEntry')}
                {/* Save Entry */}
            </Button>
        </div>
    ), [toggleAddAssetModal, handleAddAsset, deleteAsset]);

    return (
        <div className="other-assets-comp">
            <div>
                {assetsTableData?.length < 1 ? <EmptyComp text={t('OtherAssetStep.noAssets')} className="mb-40"/> : ''}

                <AddButton
                    className="mb-40"
                    onClick={() => toggleAddAssetModal(true)}
                    label={t('OtherAssetStep.button.addProperty')}
                />
            </div>
            {assetsTableData?.length > 0 ? (
                <AssetsDataTable
                    data={assetsTableData}
                    onEditAssetRow={handleEditAssetRow}
                    categoryOptions={categoryOptions?.data || []}
                    instituteOptions={instituteOptions?.data || []}
                    strategyOptions={strategyOptions?.data || []}
                    loading={isTableLoading}
                    totalAssetsData={totalAssetsData}
                    assetDataCategories={assetDataCategories}
                />
            ) : null}

            {showAddAssetModal && (
                <Modal
                    width={1160}
                    borderRadius
                    buttonsStreched={false}
                    visible={showAddAssetModal}
                    className="other-assest-modal"
                    footer={renderFooter(defaultValues?.id || false)}
                    closable
                    onCancel={() => toggleAddAssetModal(false)}
                    closeIcon={(
                        // @ts-ignore
                        <Icon
                            type={ICON_TYPE_CLOSE_SLIM}
                            size={20}
                        />
                    )}
                >
                    <Title type={2} className="font-normal mb-24">
                        {t('OtherAssetStep.Modal.title')}
                        {/* Add Assets */}
                    </Title>

                    <div className="add-other-assets-form flex">
                        <Controller
                            name="categoryListItemId"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Select
                                    {...field}
                                    onChange={onChange}
                                    label={t('OtherAssetStep.label.category')}
                                    options={categoryOptions?.data || []}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="categoryListItemId"
                                    error={errors?.categoryListItemId?.message}
                                    required
                                />
                            )}
                        />

                        <Controller
                            name="instituteListItemId"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Select
                                    {...field}
                                    onChange={onChange}
                                    label={t('OtherAssetStep.label.institute')}
                                    options={instituteOptions?.data || []}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="category"
                                    error={errors?.instituteListItemId?.message}
                                    required
                                />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            render={({field}) => (
                                <TextInput
                                    {...field}
                                    data-cy="description"
                                    name="description"
                                    label={t('OtherAssetStep.label.descriptionsfsdfsdfsdf')}
                                    error={errors?.description?.message}
                                    placeholder="Description"
                                />
                            )}
                        />

                        <Controller
                            name="value"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.value')}
                                    data-cy="OtherAssetStep.value"
                                    error={errors?.value?.message}
                                    prefix="CHF "
                                    textRight
                                    required
                                />
                            )}
                        />
                    </div>

                    <div className="add-other-assets-form strategy-form flex">
                        <Controller
                            name="strategyListItemId"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Select
                                    {...field}
                                    label={t('OtherAssetStep.label.strategy')}
                                    options={strategyOptions?.data || []}
                                    placeholder={t('position.pleaseSelect')}
                                    data-cy="strategyListItemId"
                                    error={errors?.strategyListItemId?.message}
                                    onChange={(val: number) => {
                                        onChange(val);
                                        handleStrategyAllocations(val);
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name="allocations.stocks"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.stocks')}
                                    data-cy="OtherAssetStep.allocations.stocks"
                                    error={errors?.stocks?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />

                        <Controller
                            name="allocations.liquidity"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.liquidity')}
                                    data-cy="OtherAssetStep.allocations.liquidity"
                                    error={errors?.liquidity?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />
                        <Controller
                            name="allocations.bonds"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.bonds')}
                                    data-cy="OtherAssetStep.allocations.bonds"
                                    error={errors?.bonds?.message}
                                    suffix=" %"
                                    textRight
                                    defaultValue={defaultValues?.allocations?.bonds ?? null}
                                />
                            )}
                        />
                        <Controller
                            name="allocations.realEstate"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.realEstate')}
                                    data-cy="OtherAssetStep.allocations.realEstate"
                                    error={errors?.realEstate?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />
                        <Controller
                            name="allocations.rawMaterial"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.rawMaterial')}
                                    data-cy="OtherAssetStep.allocations.rawMaterial"
                                    error={errors?.rawMaterial?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />
                        <Controller
                            name="allocations.tiedAssets"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.tiedAssets')}
                                    data-cy="OtherAssetStep.allocations.tiedAssets"
                                    error={errors?.tiedAssets?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />
                        <Controller
                            name="allocations.other"
                            control={control}
                            render={({field}) => (
                                <NumberInput
                                    {...field}
                                    placeholder=""
                                    thousandSeparator="'"
                                    decimalSeparator="."
                                    label={t('OtherAssetStep.label.allocations.other')}
                                    data-cy="OtherAssetStep.allocations.other"
                                    error={errors?.other?.message}
                                    suffix=" %"
                                    textRight
                                />
                            )}
                        />
                        <div className="form-group">
                            <span
                                className={`total-assets-percentage ${totalAssetPercentage === 100.0 ? ' total-success' : ''}`}
                            >
                                {totalAssetPercentage}
                                {' '}
                                %
                            </span>
                        </div>
                    </div>
                    {error && (
                        <div className="add-asset-error text-center">
                            {error}
                        </div>
                    )}
                </Modal>
            )}
        </div>
    );
};

export default OtherAssets;
